const checkEventOverlap = (events) => {
    const result = {};

    // Iterar sobre cada evento
    events.forEach(event => {
        const fromDate = new Date(event.eventDateFrom);
        const toDate = new Date(event.eventDateTo);

        // Convertir las fechas a formato YYYY-MM-DD para agrupar por día
        const dayKey = `${('0' + fromDate.getDate()).slice(-2)}`;

        // Si no hay entrada para ese día, crearla
        if (!result[dayKey]) {
            result[dayKey] = [];
        }

        // Obtener la lista de eventos para ese día
        const dayEvents = events.filter(e => {
            const eFromDate = new Date(e.eventDateFrom);
            const eToDate = new Date(e.eventDateTo);
            const eDayKey = `${('0' + eFromDate.getDate()).slice(-2)}`;
            return dayKey === eDayKey;
        });

        // Verificar si hay solapamiento con otros eventos para ese día
        let overlaps = 0;
        for (let i = 0; i < dayEvents.length; i++) {
            const e = dayEvents[i];
            const eFromDate = new Date(e.eventDateFrom);
            const eToDate = new Date(e.eventDateTo);
            
            // Comprobar si hay solapamiento
            if (
                (fromDate >= eFromDate && fromDate <= eToDate) ||
                (toDate >= eFromDate && toDate <= eToDate) ||
                (fromDate <= eFromDate && toDate >= eToDate)
            ) {
                overlaps++;
            }
        }
        result[dayKey] = overlaps > 0 && event.idCategory !== 25 ? overlaps : 1;
    });

    // Obtener todos los días del mes
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const daysInMonth = new Date(year, month + 1, 0).getDate();

    // Llenar el resultado para todos los días del mes
    for (let i = 1; i <= daysInMonth; i++) {
        const dayKey = `${('0' + i).slice(-2)}`;
        if (!result[dayKey]) {
            result[dayKey] = 1; // Día sin eventos
        }
    }

    return result;
}


export default checkEventOverlap;