import React, { useState, useEffect, useRef, useContext } from 'react';
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '@context/auth/AuthContext'
import { Grid, Card, Box, Typography, Button } from '@mui/material';
import Dialog from '@components/Dialog'
import HeaderTitle from '@components/HeaderTitle';
import Loading from "@components/Loading";
import calculatePagesQuestions from '@helpers/calculatePagesQuestions'
import { TypeQuestion } from './components/TypeQuestion';
import { SurveyQuestion } from './components/SurveyQuestion';
import { useCommonStyles } from '@styles/common.style'
import { useStyles } from '@styles/evaluator.style'
import { useTranslation } from 'react-i18next'
import serviceEvaluatorInfo from '@services/serviceEvaluatorInfo'
import serviceEvaluatorData from '@services/serviceEvaluatorData'
import {snackActions} from '@helpers/snackbarUtils'
import ArrowB from '@assets/images/surveys/arrow_b.gif'
import PlusB from '@assets/images/surveys/plus_b.gif'
import MinusB from '@assets/images/surveys/minus1_b.gif'

const Survey = () => {    
    const classes = useCommonStyles()
    const classes2 = useStyles()
    const { i18n, t } = useTranslation() 
    const navigate = useNavigate()
    const { logout } = useContext(AuthContext)
      
    const lang = sessionStorage.getItem('lang');
    const [loading, setLoading] = useState(true);
    const [typeQuestions, setTypeQuestions] = useState([])
    const [surveyQuestions, setSurveyQuestions] = useState([])
    const [typesDetailsLocale, setTypesDetailsLocale] = useState([])
    const [filteredTypesDetailsLocale, setFilteredTypesDetailsLocale] = useState([])
    const [surveyTitle, setSurveyTitle] = useState('')
    const [surveySubtitle, setSurveySubtitle] = useState('')
    const [surveyInstructions, setSurveyInstructions] = useState('')
    const [showVerification, setShowVerification] = useState (false)
    const [showSentSurvey, setShowSentSurvey] = useState (false)
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [surveyAnswers, setSurveyAnswers] = useState([])
    const [page, setPage] = useState(0);
    const [typePages, setTypePages] = useState(0)
    const [surveyPages, setSurveyPages] = useState(0)
    const [totalPages, setTotalPages] = useState(0)
    const [totalRecords, setTotalRecords] = useState()
    const [allQuestionsAnswered, setAllQuestionsAnswered] = useState();
    const [idEvaluatorType, setIdEvaluatorType] = useState(0)
    const [idLanguage, setIdLanguage] = useState(lang === 'es' ? 1 
                                                : lang === 'en' ? 2 
                                                : lang === 'it' ? 3 
                                                : 1)
    const newQuestionsRef = useRef(null);
    
    // Pongo las variables fuera del if para que estén disponibles en toda la página
    let idReceiverType = null;
    let idEvaluatorParticipant = null;
    let receiver = null;

    const receiverData = sessionStorage.getItem('receiver');

    if (receiverData) {
        receiver = JSON.parse(receiverData);
        const receiverType = receiver.isParticipant;
        idReceiverType = receiverType ? 1 : 2
        idEvaluatorParticipant = receiver.idEvaluatorParticipant
    }
    const surveyCode = sessionStorage.getItem('surveyCode');

    const [surveyAnswersCompleted, setSurveyAnswersCompleted] = useState(false)
    const [showExitTest, setShowExitTest] = useState(false)

    // Cambio en valor de flex para que les leyendas no se corten a más de 900 px, y salgan en una línea a menos de 900 px.
    const [flexStyle, setFlexStyle] = useState('0.8');
    useEffect(() => {
        const handleResize = () => {
            // Operador ternario para determinar el valor de flexStyle
            const newFlexStyle = window.matchMedia("(min-width: 900px)").matches ? '0.8' : '1';
            setFlexStyle(newFlexStyle);
        };    
        // Llamada a handleResize al montar y desmontar el componente
        handleResize();    
        // Añade el evento listener
        window.addEventListener('resize', handleResize);    
        // Limpieza del evento listener al desmontar el componente
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    


    useEffect(() => {
        const minCharacters = 3; // Define aquí el mínimo de caracteres requeridos

        // Verificar si todas las preguntas tienen respuesta y cumplen con el mínimo de caracteres
        const answeredAndValid = surveyQuestions.every((question) => {
            const answer = surveyAnswers.find(
                (answer) => answer.idEvaluatorSurveyQuestion === question.idEvaluatorSurveyQuestion
            );
            return answer !== undefined && answer.value.length >= minCharacters;
        });

        setSurveyAnswersCompleted(answeredAndValid);
    }, [surveyAnswers, surveyQuestions]);


    // Funciones fetch para recuperar idEvaluatorType, TypesDetails y TypesQuestions respectivamente. Y crear sesion
    const fetchEvaluatorDetails = async (idEvaluator) => {
        const { data, error } = await serviceEvaluatorData.getEvaluatorDetails(idEvaluator);
        if (!error) {
            setIdEvaluatorType(data.idEvaluatorType)
        }
    };

    const fetchTypesDetails = async (id) => {
        const { data, error } = await serviceEvaluatorInfo.getTypesDetails(id);
        if (!error) {
            setTypesDetailsLocale(data.evaluatorLocale)     
        }
    };

    const fetchTypesQuestions = async (dataFetch) => {
        setLoading(true)
        const { data, error, pagination } = await serviceEvaluatorInfo.getTypesQuestions(dataFetch);
        if (!error) {
            setTypePages(calculatePagesQuestions(pagination.records).totalPageNumber)
            setTotalRecords(pagination.records)
            setTypeQuestions(data)
            setTypeQuestions((data) => 
                data.map((question, index) => ({
                ...question, 
                pageNumber: Math.floor(index / (data.length / calculatePagesQuestions(pagination.records).totalPageNumber)) + 1, 
            }))
        );
    
            if ( surveyTitle !== '' ) {
                setLoading(false)
            }
        }
    };

    const fetchSurveyQuestions = async (dataFetch) => {
        setLoading(true)
        const { data, error, pagination } = await serviceEvaluatorInfo.getSurveyQuestions(dataFetch);
        if (!error) {
            setSurveyPages(calculatePagesQuestions(pagination.records).totalPageNumber)
            setSurveyQuestions(data)
            setSurveyQuestions((data) => 
                data.map((question, index) => ({
                ...question, 
                pageNumber: Math.floor(index / (data.length / calculatePagesQuestions(pagination.records).totalPageNumber)) + 1, 
                }))
            );
            if ( surveyTitle !== '' ) {
                setLoading(false)
            }
        }
    };
    
    const createNewSession = async (dataSession) => {
        const { data, error } = await serviceEvaluatorData.newSessions(dataSession);
    };

    // Recupero idEvaluatorType y seteo idioma
    useEffect(() => {
        fetchEvaluatorDetails(receiver?.idEvaluator)
        setIdLanguage(i18n.language === 'es' ? 1 
        : i18n.language === 'en' ? 2 
        : i18n.language === 'it' ? 3 
        : 1)
    }, [])
    
    useEffect(() => {
      setIdLanguage(i18n.language === 'es' ? 1 
                    : i18n.language === 'en' ? 2 
                    : i18n.language === 'it' ? 3 
                    : 1)
    }, [i18n.language])  

    // En cuanto tengo idEvaluatorType, recupero typesDetails
    useEffect(() => {
        if (idEvaluatorType !== 0) {
            fetchTypesDetails(idEvaluatorType)
        }
    }, [idEvaluatorType])

    useEffect(() => {
        if (filteredTypesDetailsLocale.length !== 0) {
            setSurveyTitle(filteredTypesDetailsLocale[0].surveyTitle)
            setSurveySubtitle(filteredTypesDetailsLocale[0].surveySubtitle)
            setSurveyInstructions(filteredTypesDetailsLocale[0].surveyInstructions)
            setLoading(false)
        }
    }, [filteredTypesDetailsLocale])

    useEffect(() => {
        if (typesDetailsLocale.length !== 0) {
                filterTypesDetailsLocale(idReceiverType,idLanguage)
            }
    }, [typesDetailsLocale, idLanguage])

    // Función para filtrar TypesDetailsLocale dependiendo del idReceiver y del idLanguage
    const filterTypesDetailsLocale = (idReceiverType,idLanguage) => {
        const filteredData = typesDetailsLocale.filter(item => {
            return item.idReceiverType === idReceiverType && item.idLanguage === idLanguage;
        });
        setFilteredTypesDetailsLocale(filteredData)
    }    
    
    // Recuperamos todas las preguntas y las guardamos en sus estados y/o cambiar idioma

    useEffect(() => {
        if (totalPages !== typePages + surveyPages) {
            setTotalPages(typePages + surveyPages)
        }
    }, [surveyPages, typePages, totalPages])

    useEffect(() => { 
        const data = {
            pageSize: 1000, 
            pageNumber: 0, 
            globalSearch: '', 
            search: [
                {
                    SearchField: 'idEvaluatorType', 
                    SearchMode: 'EqualTo', 
                    SearchValue: idEvaluatorType, 
                },
                {
                    SearchField: 'IdLanguage', 
                    SearchMode: 'EqualTo', 
                    SearchValue: idLanguage, 
                },
                {
                    SearchField: 'IdReceiverType', 
                    SearchMode: 'EqualTo', 
                    SearchValue: idReceiverType, 
                },
            ],
            order: [
                {
                    OrderField: 'OrderPosition',
                    OrderAsc: true 
                },
            ]
        };

        if (idEvaluatorType !== 0) {
            fetchTypesQuestions(data)
        }
    }, [idLanguage, idEvaluatorType])

    // Recuperamos las surveys y cambiar idioma
    useEffect(() => { 
        const data = {
            pageSize: 1000, 
            pageNumber: 0, 
            globalSearch: '', 
            search: [
                {
                    SearchField: 'idEvaluatorSurvey', 
                    SearchMode: 'EqualTo', 
                    SearchValue: idEvaluatorType, 
                },
                {
                    SearchField: 'IdLanguage', 
                    SearchMode: 'EqualTo', 
                    SearchValue: idLanguage, 
                },
                {
                    SearchField: 'IdReceiverType', 
                    SearchMode: 'EqualTo', 
                    SearchValue: idReceiverType, 
                },
            ],
            order: [
                {
                    OrderField: 'IdEvaluatorSurveyQuestion',
                    OrderAsc: true 
                },
            ]
        };

        if (idEvaluatorType !== 0 && typeQuestions.length === 0 && idReceiverType ===2) {
            fetchSurveyQuestions(data)
        }
        setLoading(false)
    }, [idLanguage, idEvaluatorType])

    // Verifica si todas las preguntas tienen una opción seleccionada
    useEffect(() => {  
        const filteredQuestions = typeQuestions.filter((question) => {
            return question.pageNumber === page + 1;
          });
        const answered = filteredQuestions.every((question) => {
            const typeQuestionId = question.idEvaluatorTypeQuestion -1;
            return selectedOptions[typeQuestionId] !== undefined && selectedOptions[typeQuestionId] !== "";
        });
        setAllQuestionsAnswered(answered);
    }, [selectedOptions, page, typeQuestions]);

   
    const handleOptionChange = (idQuestion, option) => {
        const existingItem = selectedOptions.find(
            (item) => item.idEvaluatorTypeQuestion === idQuestion
        );
        
        if (existingItem) {
            const updatedOptions = selectedOptions.map((item) =>
            item.idEvaluatorTypeQuestion === idQuestion
                ? { ...item, value: Number(option) }
                : item
            );
        
            setSelectedOptions(updatedOptions);
        } else {
            setSelectedOptions((prevOptions) => [
            ...prevOptions,
            {
                idEvaluatorTypeQuestion: idQuestion,
                value: Number(option),
            },
            ]);
        }
    };

    const handleSurveyChange = (idQuestion, text) => {
        const existingItem = surveyAnswers.find(
            (item) => item.idEvaluatorSurveyQuestion === idQuestion
        );
        
        if (existingItem) {
            const updatedOptions = surveyAnswers.map((item) =>
            item.idEvaluatorSurveyQuestion === idQuestion
                ? { ...item, value: text }
                : item
            );
        
            setSurveyAnswers(updatedOptions);
        } else {
            setSurveyAnswers((prevOptions) => [
            ...prevOptions,
            {
                idEvaluatorSurveyQuestion: idQuestion,
                value: text,
            },
            ]);
        }
    };

    
    // Manejo los cambios de página y botones
    const handleNextPage = () => {
        if (allQuestionsAnswered) {
            const nextPage = page <= totalPages - 2 ? page + 1 : 0;
            setPage(nextPage);
            if (newQuestionsRef.current) {
                window.scrollTo({
                    top: newQuestionsRef.current.offsetTop,
                    behavior: "smooth", 
                });
            }
        } else {
            snackActions.error(t('EVALUATOR.ERR_VERIFICATION_NEXT'))
        }
    }

    const handlePreviousPage = () => {
        const nextPage = page > 0 ? page - 1 : totalPages - 1;
        setPage(nextPage);
        if (newQuestionsRef.current) {
            window.scrollTo({
                top: newQuestionsRef.current.offsetTop,
                behavior: "smooth", 
            });
        }
    }

    const handleFinishSurvey = () => {
        if (allQuestionsAnswered) {
            setShowSentSurvey(true); // Mostrar el mismo diálogo para evaluación y autoevaluación
        } else {
            snackActions.error(t('EVALUATOR.ERR_VERIFICATION_FINISH'))
        }
    }

    const handleBackSurvey = () => {
        setShowVerification(false)
    }
    
    const handleSentSurvey = () => {
        setShowSentSurvey(false)
        setLoading(true)
        const DateNow =  new Date();
        const dataSession = {
            SessionDate: DateNow,
            IdReceiverType: idReceiverType,
            IdEvaluatorParticipant: idEvaluatorParticipant,       
            EvaluatorAnswers: selectedOptions,
            EvaluatorSurveyAnswers: surveyAnswers
        };
        createNewSession(dataSession)
        setLoading(false)
        sessionStorage.removeItem('receiver');
        if (idReceiverType === 2) {
            sessionStorage.removeItem('surveyCode');
        }
        snackActions.success(t('EVALUATOR.FINISH_TEST'))
        setShowExitTest(true); // Muestra la ventana con el mensaje "EXIT_TEST"

    }

    if (loading) {
        return <Loading />;
    }

    //Estilos del Modal con el mensaje
    const overlayStyle = {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      zIndex: 999
    };
    
    const modalStyle = {
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'white',
      padding: '25px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
      zIndex: 1000,
      borderRadius: '10px',
      width: '80%',
      textAlign: 'center'
    };
    
    const messageStyle = {
      fontSize: '25px',
      //fontWeight: 'bold',
      textAlign: 'left !important',
      display: 'inline-block',
    };

    return (
        <>
        {showSentSurvey && (
            <Dialog
                open={showSentSurvey ? true : false}
                width='auto'
                height='auto'
                hasCloseBtn={false}
                title={t('EVALUATOR.SENT_SURVEY')}
                actions={
                <Box display='flex' width='100%' justifyContent='flex-end'>
                    <Button
                        variant='contained'
                        onClick={handleSentSurvey}
                        aria-label='move selected left'
                        style={{marginRight: '10px'}}
                        >
                        {t('BUTTON.ACCEPT')}
                    </Button>
                    <Button
                        variant='contained'
                        onClick={() => setShowSentSurvey(false)}
                        aria-label='move selected left'
                    >
                        {t('BUTTON.CANCEL')}
                    </Button>
                </Box>
                }
            />)}
        <div className={classes.container}>
            <div style={{ position: 'relative' }}>
            {!showVerification && (
                <>
                    {page !== typePages && (
                        <HeaderTitle
                            title={surveyTitle}
                            subtitle={surveySubtitle}
                        />
                    )}
                    <br />
                    <Grid container spacing={2}>
                        {page !== typePages && (
                            <>
                                {/* 
                                <Grid item xs={12} md={10} sx={{fontSize: '1.1em'}}>
                                    {t('EVALUATOR.SURVEY_TEXT1')}{totalRecords}{t('EVALUATOR.SURVEY_TEXT1_2')}
                                </Grid>
                                */}
                                <Grid item xs={12} md={11} display={'flex'} justifyContent={'right'} mr={0}>
                                    {t('EVALUATOR.PAGE')} {page + 1}/{typePages}
                                </Grid>
                                <Grid item xs={11} className={classes2.justified}>
                                    <Typography>
                                        {surveyInstructions}
                                    </Typography>
                                    {/*<Typography className={classes2.bold} mt={1}>
                                        {t('EVALUATOR.SURVEY_TEXT3')}
                                    </Typography>*/}
                                </Grid>

                                <Card className={classes2.legend} style={{ borderRadius: '15px' }}>
                                    <Grid container alignItems="center" justifyContent="center">
                                        <Grid item xs={12} style={{display: 'flex',alignItems: 'center',marginBottom: '3px', marginRight: '15px'}}>
                                            <div style={{backgroundColor: 'white',width: '20px',height: '20px',display: 'flex',justifyContent: 'center',alignItems: 'center',marginRight: '8px', border: `1px solid #0085B4`, borderRadius: '10%'}}>
                                            <div className={classes2.plusB} style={{backgroundSize: 'contain',width: '15px',height: '15px'}} />
                                            </div>
                                            <Typography style={{fontSize: '0.9em',flex: flexStyle, whiteSpace: 'normal'}}>{t('EVALUATOR.LEGENDA')}</Typography>
                                        </Grid>
                                        <Grid item xs={12} style={{display: 'flex',alignItems: 'center',marginBottom: '3px', marginRight: '15px'}}>
                                            <div style={{backgroundColor: 'white',width: '20px',height: '20px',display: 'flex',justifyContent: 'center',alignItems: 'center',marginRight: '8px', border: `1px solid #0085B4`, borderRadius: '10%'}}>
                                            <div className={classes2.arrowB} style={{backgroundSize: 'contain',width: '15px',height: '15px'}} />
                                            </div>
                                            <Typography style={{fontSize: '0.9em', flex: flexStyle, whiteSpace: 'normal'}}>{t('EVALUATOR.LEGENDB')}</Typography>
                                        </Grid>
                                        <Grid item xs={12} style={{display: 'flex',alignItems: 'center',marginBottom: '3px', marginRight: '15px'}}>
                                            <div style={{backgroundColor: 'white',width: '20px',height: '20px',display: 'flex',justifyContent: 'center',alignItems: 'center',marginRight: '8px', border: `1px solid #0085B4`, borderRadius: '10%'}}>
                                            <div className={classes2.minusB} style={{backgroundSize: 'contain',width: '15px',height: '15px'}} />
                                            </div>
                                            <Typography style={{fontSize: '0.9em',flex: flexStyle, whiteSpace: 'normal'}}>{t('EVALUATOR.LEGENDC')}</Typography>
                                        </Grid>
                                    </Grid>
                                </Card>
                            </>
                        )}
                        <Grid item xs={12} ref={newQuestionsRef}>
                            <Card className={classes2.card} >
                                {page !== typePages && typeQuestions
                                    .filter((question) => question.pageNumber === (page + 1))
                                    .map((question, index) => (
                                    <TypeQuestion
                                        key={index}
                                        index={question.orderPosition}
                                        question={question.questionText}
                                        selectedOption={ String(selectedOptions?.find( item => item.idEvaluatorTypeQuestion === question.idEvaluatorTypeQuestion)?.value)  || '' }
                                        onOptionChange={(option) => handleOptionChange(question.idEvaluatorTypeQuestion, option)}
                                    />
                                ))}
                                {page === typePages && (
                                    <>                                       
                                        <HeaderTitle
                                            // title={t('EVALUATOR.TITLE_SURVEY')}
                                            subtitle={t('EVALUATOR.SUBTITLE_SURVEY') + ':'}
                                        />
                                        {surveyQuestions.map((question, index) => (
                                            <SurveyQuestion
                                            key={index}
                                            index={question.orderPosition}
                                            question={question.questionText}
                                            answer={surveyAnswers?.find( item => item.idEvaluatorSurveyQuestion === question.idEvaluatorSurveyQuestion)?.value  || ''}
                                            onChange={(text) => handleSurveyChange(question.idEvaluatorSurveyQuestion, text)}
                                            />
                                        ))}
                                    </>
                                )}
                                <Grid container spacing={1} display={'flex'} justifyContent={'end'}>
                                    {page > 0 && (
                                        <Grid item xs={6} display={'flex'} justifyContent={'center'} mt={4} mb={5}>
                                            <Button className={classes2.btn + ' ' + classes2.bgOrangeOtto} variant='contained' size='large' onClick={handlePreviousPage}>
                                            {t('EVALUATOR.BACK')}
                                            </Button>
                                        </Grid>
                                    )}
                                    {(page < totalPages - 1) && (
                                        <Grid item xs={6} display={'flex'} justifyContent={'center'} mt={4} mb={5}>
                                            <Button className={classes2.btn} variant='contained' size='large' onClick={handleNextPage}>
                                            {t('EVALUATOR.NEXT')}
                                            </Button>
                                        </Grid>
                                    )}
                                    { page === totalPages - 1 && (
                                        <Grid item xs={6} display={'flex'} justifyContent={'center'} mt={4} mb={5}>
                                          <Button className={classes2.btn} variant='contained' size='large' onClick={handleFinishSurvey} disabled={!surveyAnswersCompleted}>
                                            {t('EVALUATOR.FINISH')}
                                          </Button>
                                        </Grid>
                                    )}
                                </Grid>
                            </Card>
                        </Grid>
                    </Grid>
                </>
            )}
            { showVerification && idReceiverType === 1 && (
                <>
                    <Card >
                        <Grid container spacing={2} p={3}>                            
                            <Grid item xs={12}  display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                                <Typography p={1}>
                                    {t('EVALUATOR.CONFIRMATION_TEXT')}
                                </Typography>
                                <Box className={classes2.card2} m={4}>
                                    <Button className={classes2.btn  + ' ' +  classes2.bgOrangeOtto} variant='contained' size='large' mr={1} onClick={handleBackSurvey}>
                                        {t('EVALUATOR.BACK')}
                                    </Button>
                                    <Button className={classes2.btn} variant='contained' size='large' onClick={handleSentSurvey}>
                                        {t('EVALUATOR.FINISH')}
                                    </Button>
                                </Box>
                                <Box className={classes2.card3} m={4}>
                                    <Typography mb={2} style={{textAlign: 'justify'}}>
                                        {t('EVALUATOR.SURVEY_TEXT4')}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Card>
                </>
            )}
            </div>
        </div>
        <div>
          {showExitTest && (
            //Ventana con el mensaje
            <>
              <div style={overlayStyle}></div> {/* Fondo oscuro */}
              <div style={modalStyle}>
                <span style={messageStyle}>
                  {t('EVALUATOR.EXIT_TEST')}
                </span>
              </div>
            </>
          )}
        </div>
    </>
)};

export default Survey;