import React, {useEffect, useState} from 'react'
import { Typography, Grid, TextareaAutosize } from '@mui/material'
import { useStyles } from '@styles/evaluator.style'
import { useTranslation } from 'react-i18next'

export const SurveyQuestion = ({index, question, answer, onChange}) => {
  const classes2 = useStyles()
  const { t } = useTranslation()
  const maxCharacters = 250
  const minCharacters = 3 // Define el mínimo de caracteres mínimos

  const charactersRemaining = maxCharacters - answer.length
  const charactersMinimum = answer.length < minCharacters // Verifica si la longitud del texto está por encima del mínimo

  return (
    <div key={index}>
        <Grid container spacing={2} style={{borderBottom: '1px dotted #0085b4'}} py={3}>
            <Grid item xs={12} >
                <Typography style={{textAlign: 'justify', fontWeight: 'bold', fontSize: '1.1em'}}>{question}</Typography>
            </Grid>
            <Grid item xs={12} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                <TextareaAutosize
                    aria-label={question}
                    name={question}
                    minRows={3}
                    onChange={(event) => onChange(event.target.value)}
                    value={answer}
                    style={{
                        width: '80%',
                        fontSize: '1.1rem',
                        padding: '3px',
                        borderColor: charactersMinimum ? 'red' : '',
                    }}
                />
            </Grid>
            <Grid container spacing={1} ml={5}>
                <Grid item xs={12}>
                    <Typography variant={'caption'} style={{ fontWeight: 'bold' }}>
                        {t('EVALUATOR.CHARACTERS_REMAINING')}: {charactersRemaining}
                    </Typography>
                </Grid>
                {charactersMinimum && (
                    <Grid item xs={12}>
                        <Typography variant={'caption'} style={{ color: 'red', fontWeight: 'bold' }}>
                            {t('EVALUATOR.MIN_CHARACTERS_REQUIRED', { min: minCharacters })}
                        </Typography>
                    </Grid>
                )}
            </Grid>

        </Grid>
    </div>
  )
}
