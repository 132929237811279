import React, { Fragment, useContext, useRef, useState, useEffect } from 'react'
import {
  Grid,
  TextField,
  Typography,
  Select,
  MenuItem,
  Box,
  Button,
  FormControlLabel,
  RadioGroup,
  Radio,
  CircularProgress,
  Divider,
  ListItem,
  ListItemText,
  ListItemIcon,
  Checkbox,
  List
} from '@mui/material'
import MuiAccordion from '@mui/material/Accordion'
import DeletePopover from '@components/DeletePopover'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import { styled } from '@mui/material/styles'
import {ArrowForwardIosSharp, ThumbsUpDown} from '@mui/icons-material'
import Card from '@components/Card'
import { ListContext } from '@context/ListContext'
import serviceCycleTypes from '@services/serviceCycleTypes'
import Dialog from '@components/Dialog'
import { transformCycleTypeJourneysToCyclesJourneys } from '@helpers/cyclesHelper'
import CheckBox from '@components/CheckBox'
import Icon from '@components/Icon'
import CameraAltIcon from '@mui/icons-material/CameraAlt'
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto'
import { useTranslation } from 'react-i18next'
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { useStyles } from '@styles/form.style';
import { snackActions } from '@helpers/snackbarUtils'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import moment from 'moment'
import ChangeImage from '@components/ChangeImage'
import SectionCompanies from './SectionCompanies'

const Accordion = styled(props => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0
  },
  '&:before': {
    display: 'none'
  }
}))

const AccordionSummary = styled(props => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharp sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)'
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1)
  }
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)'
}))

const SectionForm = ({
  formData,
  handleChange,
  cycleTypesList,
  setFormData,
  setLoadingJourneys,
  journeyTypeModeLive,
  setJourneyTypeModeLive,
  cycleTypeModeLive,
  setCycleTypeModeLive,
  journeysTypeModeCodes,
  evaluatorTypes,
  setEvaluatorTypes,
  evaluatorTypeList,
  timezonesList
}) => {
  const { t } = useTranslation()
  const { countriesList, languageList } = useContext(ListContext)
  const [openDialogJourneys, setOpenDialogJourneys] = useState(false)
  const [openDialogPicture, setOpenDialogPicture] = useState(false)
  const [titleLocales, setTitlesLocale] = useState([])
  const [evaluatorSelected, setEvaluatorSelected] = useState()
  const idCycleTypeRef = useRef()
  const idEvaluatorTypeRef = useRef()
  const [evaluatorChecked, setEvaluatorChecked] = useState(formData?.evaluatorDefault ? [formData?.evaluatorDefault] : [])
  const [loading, setLoading] = useState(false)
  const [start, setStart] = useState(false)
  const [startModified, setStartModified] = useState(false)
  const [manualModeChange, setManualModeChange] = useState(false); 
  const [openEvaluator, setOpenEvaluator] = useState(false); 
  const classes = useStyles();

  // Manejador para cuando la imagen se actualice en ChangeImage
  const handleImageSave = (newImage) => {
    setFormData(prev => ({
        ...prev,
        cycleImage: newImage
    }));
  };

  const extendedProps = {
    journeyTypeModeLive: journeyTypeModeLive, 
    setJourneyTypeModeLive: setJourneyTypeModeLive,
    cycleTypeModeLive: cycleTypeModeLive,
    setCycleTypeModeLive: setCycleTypeModeLive,
    journeysTypeModeCodes: journeysTypeModeCodes
  }

  useEffect(() => {
    if (formData.cycleTypeMode == 0 || formData.cycleTypeMode == null) {
      setFormData(prevState => {
        return {
          ...prevState,
          cycleTypeMode: 1
        }
      })
    }
  }, [])

  useEffect(() => {
    setFormData(prevState => {
      return {
        ...prevState,
        cycleTypeMode: cycleTypeModeLive
      }
    })
  }, [cycleTypeModeLive])

  useEffect(() => {
 
    if (journeyTypeModeLive !== undefined && start) {
      if (manualModeChange) {
        // Actualiza journeyTypeModeLive solo cuando el cambio proviene de una interacción manual
        if (cycleTypeModeLive == 1 || cycleTypeModeLive == 2) {
          setJourneyTypeModeLive((prevState) => prevState.map((j) => parseInt(cycleTypeModeLive)));
        } else {
          if (journeyTypeModeLive.length == 2) {
            setJourneyTypeModeLive((prevState) => prevState.map((j, i) => (i === 0 ? 1 : 2)));
          } else {
            setJourneyTypeModeLive((prevState) =>
              prevState.map((j, i) => (i === 0 || i === journeyTypeModeLive.length - 1 ? 1 : 2))
            );
          }
        }
        // Restablece manualModeChange a false para futuras interacciones
        setManualModeChange(false);
      }
    }
    setStart(true)
  }, [cycleTypeModeLive])

  useEffect(() => {
    
    if(startModified){
      const dateEnd = new Date(formData.cycleJourneys[formData.cycleJourneys.length - 1].cycleJourneyDateTo);
      const now = new Date();
      if(dateEnd < now) {
        setFormData(prevState => {
          return {
            ...prevState,
            isModified: true
          }
        })
      }
    }
    setStartModified(true);
  }, [formData.isActive, formData.isVisible])

  useEffect(() => {    
    if(titleLocales.length > 0){
      if(formData.idLanguage === 1 || !formData.idLanguage){
        setFormData(prevState => {
          return {
            ...prevState,
            cycleRemarks: titleLocales[0].cycleTypeName
            
          }
        })
      } else if(formData.idLanguage === 2 && titleLocales[1]){
          setFormData(prevState => {
            return {
              ...prevState,
              cycleRemarks: titleLocales[1].cycleTypeName
              
            }
          })
      } else if(formData.idLanguage === 3 && titleLocales[2]){
          setFormData(prevState => {
            return {
              ...prevState,
              cycleRemarks: titleLocales[2].cycleTypeName
              
            }
          })
      } else {
        setFormData(prevState => {
          return {
            ...prevState,
            cycleRemarks: titleLocales[0].cycleTypeName
            
          }
        })
      }
      setLoading(false)
    }
  }, [formData.idLanguage, titleLocales]) 

  const handleChangeSelectEv = e => {
    idEvaluatorTypeRef.current.value = e.target.value
    idEvaluatorTypeRef.current.name = e.target.name
    const { value, name } = idEvaluatorTypeRef.current
    const objetoEncontrado = evaluatorTypeList.find(item => item.idEvaluatorType === value);
    setEvaluatorSelected(objetoEncontrado)
  }

  const addEvaluatorType = () =>{
    const objetoEnLista = evaluatorTypes.find(item => item.idEvaluatorType === evaluatorSelected.idEvaluatorType);
    const objetoEncontrado = evaluatorTypeList.find(item => item.idEvaluatorType === evaluatorSelected.idEvaluatorType);
    if(objetoEncontrado !== undefined && objetoEnLista === undefined){
      const tempEvaluatorTypes = [...evaluatorTypes, evaluatorSelected];
      setEvaluatorTypes(tempEvaluatorTypes)
      if(evaluatorChecked?.length==0) setEvaluatorChecked([evaluatorSelected.idEvaluatorType])
    }
  }

  const removeEvaluatorFromList = id => {
    const newEvaluatorTemp = evaluatorTypes.filter(
      type => type.idEvaluatorType !== id
    )
    const objetoEncontrado = evaluatorChecked.find(item => item === id);
    if(objetoEncontrado !== undefined){
      
      if(newEvaluatorTemp?.length>0) setEvaluatorChecked([newEvaluatorTemp[0].idEvaluatorType])
      if(newEvaluatorTemp?.length == 1) 
      setFormData(prevState => {
        const updatedParticipants = prevState.cycleParticipants.map((participant, i) => {
            return {
              ...participant,
              idEvaluatorType: formData.evaluatorDefault
            }
        })
  
        return {
          ...prevState,
          cycleParticipants: updatedParticipants
        };
      });
    
    }
    setEvaluatorTypes(newEvaluatorTemp)
  }

  const saveEvaluatorType = () =>{
    if(evaluatorChecked?.length>0 && formData?.evaluatorDateInit != undefined && formData.evaluatorDateEnd != undefined && formData?.evaluatorDateInit != null && formData.evaluatorDateEnd != null ){
    setFormData(prevState => {
          return {
            ...prevState,
            evaluatorTypes: evaluatorTypes,
            evaluatorDefault:evaluatorChecked[0]
            
          }
        })
        setOpenEvaluator(false)
      } else {
        snackActions.error(t('EVALUATOR.DEFAULT_TYPE'))
      }
  }

  const handleChangeCycleType = async () => {
        const { value, name } = idCycleTypeRef.current
    setOpenDialogJourneys(false)
    setLoadingJourneys(true)
    setFormData({ ...formData, [name]: value })
    setLoading(true)
    const { data, error } = await serviceCycleTypes.getCycleTypeDetail(value)
  
    const newJourneys = transformCycleTypeJourneysToCyclesJourneys(data)
    if (!error) {
      setFormData(prevState => {
        return {
          ...prevState,
          cycleTypeMode: data.cycleTypeMode,
          cycleJourneys: newJourneys,
          hasEvaluator: data.hasEvaluator
        }
      })
      if(data.cycleTypeLocales.length > 0) setTitlesLocale(data.cycleTypeLocales)
    }
    const journeysTypeModeCodes =
      newJourneys != null
        ? newJourneys.map(journey => journey.idCycleJourneyModality)
        : [0]
    setJourneyTypeModeLive(journeysTypeModeCodes)
    setLoadingJourneys(false)
  }

  const handleChangeSelect = e => {
    idCycleTypeRef.current.value = e.target.value
    idCycleTypeRef.current.name = e.target.name

    if (!formData.idCycleType) {
      return handleChangeCycleType()
    }

    setOpenDialogJourneys(true)
  }

  const handleCheckbox = e => {
    const checkboxValue = +e.target.value
    if (!e.target.checked) {
      const quitEvaluatorChecked = evaluatorChecked.filter(
        evaluatorCheck => evaluatorCheck !== checkboxValue
      )
      setEvaluatorChecked(quitEvaluatorChecked)
    } else {
      const newEvaluatorChecked = [checkboxValue];
      setEvaluatorChecked(newEvaluatorChecked)
    }
  }

  const checkList = evaluator => {
    const checkItems =
      evaluatorChecked.indexOf(evaluator) !== -1 ? true : false
    return checkItems
  }

  const handleChangeRadio = e => {
    const value = e.target.value
    setCycleTypeModeLive(value)
    handleChange(e)
    setManualModeChange(true);

  }

  const handleChangeDate = (fieldName, e) => {
    let fecha = e.target ? e.target.value : e.format('YYYY-MM-DDTHH:mm') 
    setFormData({ ...formData, [fieldName]: fecha })
  };

  useEffect(() => {
    if (countriesList.length > 0 && !formData.idCountry) {
      setFormData(prevState => ({
        ...prevState,
        idCountry: countriesList[0].idCountry
      }));
    }
  }, [countriesList, formData.idCountry, setFormData]);
  
  useEffect(() => {
    if (languageList.length > 0 && !formData.idLanguage) {
      setFormData(prevState => ({
        ...prevState,
        idLanguage: languageList[0].idLanguage
      }));
    }
  }, [languageList, formData.idLanguage, setFormData]);

  useEffect(() => {
    if (!formData.cycleTimezone) {
      setFormData(prevState => ({
        ...prevState,
        cycleTimezone: timezonesList[0].idTimezone
      }));
    }
  }, [formData.cycleTimezone, timezonesList]);
  

  if (loading) {
    return (
      <Card fullHeight flex>
        <Fragment>
          <Typography variant='caption'>
            {t('CYCLES.LOADING_CYCLE_TYPE')}
          </Typography>
          <CircularProgress />
        </Fragment>
      </Card>
    )
  }

  return (
    <Fragment>
      {openDialogJourneys && (
        <Dialog
          open={openDialogJourneys}
          hasCloseBtn={false}
          height={200}
          title={t('CYCLES.DIALOG_EDIT_CYCLE')}
          subtitle={t('CYCLES.DIALOG_EDIT_CYCLE_SUBTITLE')}
          close={() => setOpenDialogJourneys(false)}
          actions={
            <Box display='flex' alignItems='center'>
              <Box mr={1}>
                <Button
                  variant='contained'
                  onClick={() => setOpenDialogJourneys(false)}
                  color='error'
                >
                  {t('BUTTON.CANCEL')}
                </Button>
              </Box>
              <Button variant='contained' onClick={handleChangeCycleType}>
                {t('BUTTON.ACCEPT')}
              </Button>
            </Box>
          }
        />
      )}
      {openDialogPicture && (
        <Dialog
          centerContent
          open={openDialogPicture}
          hasCloseBtn={false}
          width='auto'
          height='auto'
          title={t('CYCLES.ADD_PICTURE')}
          close={() => setOpenDialogPicture(false)}
          content={
            <Grid item xs={10} display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'} pl={1} pr={1}>
              {/* En onImageSave se guarda la imagen local creada desde ChangeImage cuando es un grupo nuevo */}
              <ChangeImage
                idCycle={formData.idCycle}
                imageName="cycleImage"
                onImageSave={handleImageSave}
                localImage={formData.cycleImage} // Asumiendo que formData.cycleImage almacena la última imagen guardada
              />
            </Grid>
          }
          actions={
            <Box display='flex' alignItems='center'>
              <Button
                variant='contained'
                onClick={() => {
                  setOpenDialogPicture(false)
                }}
              >
                {t('BUTTON.CLOSE')}
              </Button>
            </Box>
          }
        />
      )}
      
      {openEvaluator && (
        <Dialog
          centerContent
          open={openEvaluator}
          hasCloseBtn={true}
          width='auto'
          height='auto'
          title={t('EVALUATOR.NEW_TITLE')}
          close={() => setOpenEvaluator(false)}
          content={
            <>
              <Grid container spacing={1} style={{ marginTop: '5px', minWidth:'500px'}}>
                <Grid item xs={12}>
                  <Typography variant='caption' component='div'>
                    {t('EVALUATOR.EVALUATOR_TYPE')}*
                  </Typography>
                  <Box display='flex' alignItems='center' flexWrap='wrap' sx={{minWidth:'40%'}}>
                    <Grid container spacing={1}>
                      <Grid item xs={11} >
                        <Select
                          id='idEvaluatorType'
                          ref={idEvaluatorTypeRef}
                          size='small'
                          name='idEvaluatorType'
                          fullWidth
                          value={evaluatorSelected?.idEvaluatorType || ''}
                          onChange={handleChangeSelectEv}
                        >
                          {evaluatorTypeList?.map((evaluatorType, i) => (
                            <MenuItem
                              key={evaluatorType.idEvaluatorType}
                              value={evaluatorType.idEvaluatorType}
                            >
                              {evaluatorType.evaluatorTypeName}
                            </MenuItem>
                          ))}
                        </Select>
                        </Grid>
                      <Grid item xs={1}>
                        <Button
                          variant='contained'
                          onClick={addEvaluatorType}
                          className={classes.buttonIcon}>
                          <AddRoundedIcon color='white' />
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <List>
                  {evaluatorTypes?.map((type, index) => {
                    return (
                      <Box key={type.idEvaluatorType}>
                        <ListItem
                          sx={{
                            bgcolor: 'theme.palette.primary.main'
                          }}
                          secondaryAction={
                              <DeletePopover
                                remove={() => {
                                  removeEvaluatorFromList(
                                    type.idEvaluatorType
                                  )
                                }}
                              />                          
                          }
                        >
                          <ListItemIcon>
                            <Checkbox
                              value={type.idEvaluatorType}
                              sx={{ p: 0 }}
                              onChange={e => handleCheckbox(e)}
                              checked={checkList(type.idEvaluatorType)}
                            />
                          </ListItemIcon>

                          <ListItemText
                            primaryTypographyProps={{ fontSize: 14 }}
                            primary={
                              type?.evaluatorTypeName 
                                ? `${type.evaluatorTypeName}`
                                : t('CYCLES.NO_USER_DATA')
                            }                            
                          />
                        </ListItem>
                        <Divider />
                      </Box>
                    )
                  })}
                  </List>
                </Grid>

                {evaluatorTypes.length > 0 && (
                  <Grid item xs={12}>
                    <Grid container spacing={2} style={{justifyContent: 'center' }}>
                      <Grid item xs={12} sm={5}>
                        <Typography variant='caption' component='div'>
                          {t('LABEL.DATE_INIT')}*
                        </Typography>
                        <DateTimePicker
                          name='evaluatorDateInit'
                          slotProps={{
                            textField: {
                              size: 'small'
                            }
                          }}
                          format='DD/MM/YYYY HH:mm' // formato de fecha y hora válido para el locale en español
                          value={
                            formData?.evaluatorDateInit
                              ? moment(formData?.evaluatorDateInit)
                              : null // Usa null en lugar de cadena vacía '' para el valor de fecha y hora
                          }
                          onChange={(e) => handleChangeDate('evaluatorDateInit', e)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={5}>
                        <Typography variant='caption' component='div'>
                          {t('LABEL.EV_END')}*
                        </Typography>
                        <DateTimePicker
                          name='evaluatorDateEnd'
                          slotProps={{
                            textField: {
                              size: 'small'
                            }
                          }}
                          format='DD/MM/YYYY HH:mm' // formato de fecha y hora válido para el locale en español
                          value={
                            formData?.evaluatorDateEnd
                              ? moment(formData?.evaluatorDateEnd)
                              : null // Usa null en lugar de cadena vacía '' para el valor de fecha y hora
                          }
                          onChange={(e) => handleChangeDate('evaluatorDateEnd', e)}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </>
          }
          actions={
            <Box display='flex' alignItems='center'>
              <Button
                variant='contained'
                onClick={() => {
                  saveEvaluatorType()
                }}
              >
                {t('BUTTON.SAVE')}
              </Button>
            </Box>
          }
        />
      )}      
      
      <Card>
        <Accordion defaultExpanded={true}>
          <AccordionSummary
            sx={{
              bgcolor: 'grey.main',
              paddingLeft: '20px'
            }}
          >
            <Typography variant='subtitle1'>
              {' '}
              {t('CYCLE_TYPES.TITLE_GLOBAL_CONFIG')}
            </Typography>
          </AccordionSummary>
          
          <AccordionDetails>                  
            <Grid container spacing={1.5} component='form'>
              
              {/* Primera fila */}
              <Grid container item spacing={1} alignItems="center">
                <Grid item xs={12} sm={2}>
                  <Typography variant='caption' component='div'>
                    {t('CYCLES.CYCLE_TYPE')}*
                  </Typography>
                  <Select
                    required
                    id='idCycleType'
                    error={!formData?.idCycleType}
                    ref={idCycleTypeRef}
                    fullWidth
                    size='small'
                    name='idCycleType'
                    value={formData?.idCycleType || ''}
                    onChange={handleChangeSelect}
                  >
                    {cycleTypesList
                      .filter(cycleType => cycleType.isVisible || cycleType.idCycleType === formData?.idCycleType)
                      .map((cycleType, i) => (
                        <MenuItem
                          key={cycleType.idCycleType}
                          value={cycleType.idCycleType}
                        >
                          {cycleType.cycleTypeCode}
                        </MenuItem>
                      ))}
                  </Select>
                </Grid>		  			  
                
                <Grid item xs={12} sm={8}>
                  <Typography variant='caption' component='div'>
                    {t('CYCLES.DESCRIPTION')}
                  </Typography>
                  <TextField
                    id='cycleRemarks'
                    name='cycleRemarks'
                    size='small'
                    fullWidth
                    value={formData?.cycleRemarks || ''}
                    onChange={handleChange}
                  />
                </Grid>			  
          
                <Grid item xs={12} sm={2}>
                  <Typography variant='caption' component='div'>
                    {t('CYCLES.COUNTRY')}*
                  </Typography>
                  <Select
                    required
                    id='idCountry'
                    error={!formData?.idCountry}
                    fullWidth
                    size='small'
                    name='idCountry'
                    value={formData?.idCountry || (countriesList.length > 0 ? countriesList[0].idCountry : '')}
                    onChange={handleChange}
                  >
                    {countriesList.map(country => (
                      <MenuItem key={country.idCountry} value={country.idCountry}>
                        {country.countryName}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
			  
              {/* Segunda fila */}     
              <Grid container item spacing={1} alignItems="center">
                <Grid item xs={12} sm={5}>
                  <Typography variant='caption' component='div'>
                    {t('CYCLES.CYCLE_NAME')}*
                  </Typography>
                  <TextField
                    required
                    fullWidth
                    id='cycleName'
                    error={formData?.cycleName === ''}
                    size='small'
                    name='cycleName'
                    variant='outlined'
                    value={formData?.cycleName || ''}
                    onChange={e => {
                      handleChange(e)
                    }}
                    inputProps={{ maxLength: 100 }}
                  />
                </Grid>
          
                <Grid item xs={12} sm={5}>
                  <Typography variant='caption' component='div'>
                    {t('CYCLES.NAME_APP')}*
                  </Typography>
                  <TextField
                    required
                    fullWidth
                    id='cycleNameApp'
                    error={formData?.cycleNameApp === ''}
                    name='cycleNameApp'
                    size='small'
                    value={formData?.cycleNameApp || ''}
                    onChange={e => {
                      handleChange(e)
                    }}
                    inputProps={{ maxLength: 100 }}
                  />
                </Grid>           
                
                <Grid item xs={12} sm={2}>
                  <Typography variant='caption' component='div'>
                    {t('CYCLES.LANG')}*
                  </Typography>
                  <Select
                    required
                    id='idLanguage'
                    error={!formData?.idLanguage}
                    fullWidth
                    size='small'
                    name='idLanguage'
                    value={formData?.idLanguage || (languageList.length > 0 ? languageList[0].idLanguage : '')}
                    onChange={handleChange}
                  >
                    {languageList.map(language => (
                      <MenuItem
                        key={language.idLanguage}
                        value={language.idLanguage}
                      >
                        {language.languageName}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
							
              {/* Tercera fila */}
              <Grid container item spacing={1} alignItems="flex-end">                
                <Grid item xs={12} sm={6} lg={4} xl={3}>
                  <Box my={0}>
                    <Typography variant='caption'>
                      {t('CYCLE_TYPES.CODE')}*
                    </Typography>
                    <RadioGroup
                      id='cycleTypeMode'
                      name='cycleTypeMode'
                      value={cycleTypeModeLive}
                      onChange={handleChangeRadio}
                      row
                    >
                      {/*
                      <FormControlLabel
                        value='0'
                        control={<Radio />}
                        label={<Typography>{t('CYCLE_TYPES.GENERAL')}</Typography>}
                      />
                      */}
                      <FormControlLabel
                        value='1'
                        control={<Radio size='small' />}
                        label={
                          <Typography fontSize={12}>
                            {t('CYCLE_TYPES.FACE')}
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        value='2'
                        control={<Radio size='small' />}
                        label={
                          <Typography fontSize={12}>
                            {t('CYCLE_TYPES.VIRTUAL')}
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        value='3'
                        control={<Radio size='small' />}
                        label={
                          <Typography fontSize={12}>
                            {t('CYCLE_TYPES.MIX')}
                          </Typography>
                        }
                      />
                    </RadioGroup>
                  </Box>
                </Grid>
             
                {/* <Grid item xs={5} sm={4} md={3.5} lg={2.5} xl={2}>
                  <CheckBox
                    id='hasForm'
                    sx={{ minWidth: '100%', display: 'flex', justifyContent: 'flex-end', height: '3em', alignItems: 'center' }}
                    label={t('CYCLES.FORM')}
                    name='hasForm'
                    value={formData?.hasForm}
                    checked={formData.hasForm}
                    onChange={e => {
                      handleChange(e)
                    }}
                  />
                </Grid> */}

                <Grid item xs={5} sm={3.5} lg={2.5} xl={2}>
                  <CheckBox
                    id='isDateBlocked'
                    sx={{ minWidth: '100%', display: 'flex', justifyContent: 'flex-end', height: '3em', alignItems: 'center' }}
                    label={t('CYCLES.BLOCKED')}
                    name='isDateBlocked'
                    value={formData?.isDateBlocked}
                    checked={formData.isDateBlocked}
                    onChange={e => {
                      handleChange(e)
                    }}
                  />
                </Grid>

                {/* <Grid item xs={4} sm={2} md={2} lg={1.5}>
                  <CheckBox
                    id='isActive'
                    sx={{ minWidth: '100%', display: 'flex', justifyContent: 'flex-end', height: '3em', alignItems: 'center' }}
                    label={t('CYCLES.IS_ACTIVE')}
                    name='isActive'
                    value={formData?.isActive}
                    checked={formData.isActive}
                    onChange={e => {
                      handleChange(e)
                    }}
                  />
                </Grid> */}

                <Grid item xs={4} sm={2} lg={2}>
                  <CheckBox
                    id='isVisible'
                    sx={{ minWidth: '100%', display: 'flex', justifyContent: 'flex-end', height: '3em', alignItems: 'center' }}
                    label={t('CYCLES.IS_VISIBLE')}
                    name='isVisible'
                    value={formData?.isVisible}
                    checked={formData?.isVisible}
                    onChange={e => {
                      handleChange(e)
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={5}>
                  <Typography variant='caption' component='div'>
                    {t('CYCLES.TIMEZONES')}*
                  </Typography>
                  <Select
                    required
                    id='cycleTimezone'
                    fullWidth
                    size='small'
                    name='cycleTimezone'
                    value={formData?.cycleTimezone || ''}
                    onChange={handleChange}
                  >
                    {timezonesList.map(timezone => (
                      <MenuItem
                        key={timezone.idTimezone}
                        value={timezone.idTimezone}
                      >
                        {timezone.timezoneDescription}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>						
							
              {/* Cuarta fila */}
              <Grid container item spacing={1} alignItems="flex-start">
                <Grid item xs={12} sm={12} md={12} lg={6}>
									<SectionCompanies
                    fullWidth
										formData={formData}
										setFormData={setFormData}
									/>      
								</Grid>
                <Grid item xs={12} sm={6} md={6} lg={3}>
									{/* <Typography variant='caption' component='div'>
										{t('CYCLES.PICTURE')}
									</Typography> */}
									<Icon
                    fullWidth
										variant={formData?.cycleImage ? 'success' : 'warning'} //TODO: Cambiar la prop
										onClick={() => {
											setOpenDialogPicture(true)
										}}
										style={{ height: '42px', width: '100%', padding: 9 }}
									>
									{formData?.cycleImage ? <CameraAltIcon /> : <AddAPhotoIcon />}
										{/*  //TODO: Cambiar la prop */}
									</Icon>
								</Grid>
								
								<Grid item xs={12} sm={6} md={6} lg={3}>
									<Button
                    fullWidth
										variant="contained"
										disabled = {!formData?.hasEvaluator}
										sx={{height: '42px', padding:-3}}
										onClick={() => setOpenEvaluator(true)}
										color="primary"
									>
										{t('LABEL.EV_NAME')}
									</Button>
								</Grid>
                
							</Grid>
            </Grid>
					</AccordionDetails>
      </Accordion>
    </Card>
    </Fragment>
  )
}

export default SectionForm
