import React, { useState } from 'react';
import DynamicTable from '@components/DynamicTable';
import { IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { SwitchAccount } from '@mui/icons-material';
import Loading from '@components/Loading';
import { snackActions } from '@helpers/snackbarUtils'

const TableCycleActive = ({
  handleChangeCycle,
  participant,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const columnSearch = [
    {
      SearchField: 'IdCycleType',
      SearchValue: participant.cycleType,
      SearchMode: 'EqualTo'
    },
    {
      SearchField: 'CycleJourneyNumber',
      SearchValue: participant.journey,
      SearchMode: 'lessorequal'
    },
    {
      SearchField: 'IdCycle',
      SearchValue: participant.participant.idCycle,
      SearchMode: 'notincluded'
    }
  ];

  const ORDER = [
    {
      orderField: 'cycleName',
      orderAsc: true
    }
  ];

  const COLUMNS = [
    {
      hiddenColumn: true,
      accessor: 'idCycle',
      Header: t('LABEL.ID'),
      width: '5%'
    },
    {
      accessor: 'cycleName',
      Header: t('LABEL.NAME')
    },
    {
      hiddenColumn: true,
      accessor: 'CycleJourneyNumber',
      Header: t('LABEL.NAME')
    },
    {
      accessor: '',
      Header: t('BUTTON.CHANGE'),
      filterable: false,
      hideHeader: false,
      width: '15%',
      Cell: ({ row: { values } }) => (
        <IconButton
          aria-label='info'
          color='info'
          style={{
            padding: '0px',
            marginLeft: '25%'
          }}
          onClick={async () => {
            setLoading(true);
            try {
              await handleChangeCycle(values.idCycle);
              snackActions.success(t('CYCLES.TRANSFERRED'))
            } finally {
              setLoading(false);
            }
          }}
        >
          <SwitchAccount fontSize='inherit' />
        </IconButton>
      )
    }
  ];

  return (
    <Box flex={1} position="relative">
      {loading && (
        <Box
          position="fixed"
          top="5%"
          left="50%"
          transform="translateX(-50%)"
          zIndex="10"
        >
          <Loading />
        </Box>
      )}
      <DynamicTable
        size='small'
        columns={COLUMNS}
        endpoint='Cycles/CyclesTypeActiveList'
        isColumnsSearch={true}
        isOrdered={true}
        columnSearchParam={columnSearch}
        orderColumn={ORDER}
      />
    </Box>
  );
};

export default TableCycleActive;
