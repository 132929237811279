import React, { Fragment, useContext, useState } from 'react'
import Fallback from '@assets/images/avatar.png'
import Logo from '@assets/images/logoEvaluatorBlanco.png'
import Dialog from '@components/Dialog'
import Flags from '@components/Flags'
import Select from '@components/Select'
import Clock from '@components/Clock'
import { AuthContext } from '@context/auth/AuthContext'
import { useFlags } from '@hooks/useFlags'
import { Logout } from '@mui/icons-material'
import MenuIcon from '@mui/icons-material/Menu'
import Button from '@mui/material/Button'

import {
  AppBar,
  Avatar,
  Box,
  Grid,
  Hidden,
  Icon,
  IconButton,
  Stack,
  Switch,
  Toolbar,
  Typography
} from '@mui/material'
import { useStyles } from '@styles/header.style'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

const Header = () => {
  const { i18n, t } = useTranslation()
  const navigate = useNavigate()
  const classes = useStyles()
  const { user, logout } = useContext(AuthContext)
  const { flagOptions, FlagSelected } = useFlags()
  const [openLanguage, setOpenLanguage] = useState(false)
  const [language, setLanguage] = useState(null)

  const handleChangeLanguage = () => {
    sessionStorage.setItem('lang', language)
    i18n.changeLanguage(language)
    setOpenLanguage(false)
  }

  const areaActiveUser = sessionStorage.getItem('area')

  return (
    <Fragment>
      <Hidden mdDown>
        {/* Menú xl */}
        <AppBar position='static'>
          <Toolbar className={classes.header}>
            <Grid onClick={() => navigate('/panel')} >
              <img src={Logo} alt='logo' style={{ height: '60px' }} />
            </Grid>

            <Hidden lgDown>
              <Grid>
                {areaActiveUser === 'evaluator' ? (
                  <Typography className={classes.headerTitle}>
                    {t('EVALUATOR.AUTOEVALUATION')}
                  </Typography>
                ) : (
                  ''
                )}
                <Stack direction='row' alignItems='center'>
                  <Clock />
                </Stack>
              </Grid>
            </Hidden>

            <Grid display='flex' alignItems='center'>
              <Grid display='flex' flexDirection='column' mr={3}>
                <Stack
                  direction='row'
                  alignItems='center'
                  justifyContent='end'
                  spacing={1}
                >
                  <Typography onClick={logout} className={classes.btn}>
                    {t('TEXT.CLOSE_SESSION')}
                  </Typography>
                </Stack>
                <Stack
                  direction='row'
                  alignItems='center'
                  justifyContent='end'
                  spacing={1}
                >
                  <Dialog
                    open={openLanguage}
                    close={() => setOpenLanguage(false)}
                    title={t('TEXT.LANGUAGES')}
                    subtitle={t('TEXT.SELECT_LANGUAGE')}
                    width={300}
                    content={
                      <Flags
                        options={flagOptions}
                        handleSelect={e => {
                          setLanguage(e.target.value)
                        }}
                      />
                    }
                    actions={
                      <Box display='flex' alignItems='center'>
                        <Box mr={1}>
                          <Button
                            variant='contained'
                            onClick={() => setOpenLanguage(false)}
                            color='error'
                          >
                            {t('BUTTON.CANCEL')}
                          </Button>
                        </Box>
                        <Button
                          variant='contained'
                          onClick={handleChangeLanguage}
                        >
                          {t('BUTTON.OK')}
                        </Button>
                      </Box>
                    }
                  />
                  <Icon
                    style={{
                      height: '30px',
                      width: '30px',
                      cursor: 'pointer'
                    }}
                    onClick={() => {
                      setOpenLanguage(true)
                    }}
                  >
                    <FlagSelected />
                  </Icon>
                </Stack>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </Hidden>

      {/* Menú xs */}
      <Hidden mdUp>
        <AppBar position='static'>
          <Toolbar className={classes.header}>
            <Box>
              {/* <IconButton
                size='large'
                onClick={() => handleShowMenu(prevState => !prevState)}
                color='inherit'
              >
                <MenuIcon />
              </IconButton> */}
            </Box>

            <Box onClick={() => navigate('/panel')} style={{ cursor: 'pointer' }}>
              <img src={Logo} alt='logo' style={{ height: '60px' }} />
            </Box>

            <IconButton size='large' onClick={logout} color='inherit'>
              <Logout />
            </IconButton>
          </Toolbar>
        </AppBar>
      </Hidden>
    </Fragment>
  )
}

export default Header
