import React, { useEffect } from 'react'
import DynamicTable from '@components/DynamicTable'
import HeaderTitle from '@components/HeaderTitle'
import { useCommonStyles } from '@styles/common.style'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Moment from 'react-moment'
import { Typography } from '@mui/material'
import { IconButton } from '@mui/material'
import { EditRounded } from '@mui/icons-material'
import serviceIncidents from '@services/serviceIncidents'
import { snackActions } from '@helpers/snackbarUtils';

const Incidents = () => {
  const classes = useCommonStyles()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const OPEN_INCIDENTS = [
    {
      label: t('INCIDENT.PROGRESS_INCIDENT'),
      value: '2'
    },
    {
      label: t('INCIDENT.OPEN_INCIDENT'),
      value: '1'
    },
    {
      label: t('INCIDENT.CLOSE_INCIDENT'),
      value: '0'
    }
  ]
  const getIncident = value => {
    let res = OPEN_INCIDENTS.filter(x => x.value == value)
    return res.length ? res[0].label : '|'
  }
  const filterMap = value => {
    if (value == '') return ''
    let res = OPEN_INCIDENTS.filter(x =>
      x.label.toLowerCase().includes(value.toLowerCase())
    )
    return res.length ? res[0].value : '|'
  }
  const ORDER = [
    {
      orderField: 'dateIncident',
      orderAsc: false
    }
  ]
  const COLUMNS = [
    {
      accessor: '',
      Header: 'Info',
      filterable: false,
      hideHeader: true,
      width: '5%',
      Cell: ({ row: { values } }) => (
        <IconButton
          aria-label='info'
          color='info'
          style={{
            padding: '0px'
          }}
          onClick={() => {
            handleShowIncidentsDetail(values.idIncident)
          }}
        >
          <EditRounded fontSize='inherit' />
        </IconButton>
      )
    },
    {
      accessor: 'idIncident',
      Header: t('LABEL.ID'),
      filterable: false,
      width: '5%',
      hiddenColumn: true
    },
    {
      accessor: 'dateIncident',
      Header: t('LABEL.DATE'),
      Cell: props => (
        <Typography variant='body2'>
          <Moment format='DD[/]MM[/]yyyy'>{props.value}</Moment>
        </Typography>
      )
    },
    {
      accessor: 'openIncident',
      Header: t('LABEL.STATE'),
      filterMap: x => filterMap(x),
      Cell: props => (
        <Typography variant='body2'>{getIncident(props.value)}</Typography>
      )
    },
    {
      accessor: 'descriptionIncident',
      Header: t('LABEL.DESCRIPTION'),
      Cell: props => <Typography variant='body2'>{props.value}</Typography>
    },
    {
      accessor: 'cycleName',
      Header: t('CYCLES.CYCLE_NAME'),
      Cell: props => <Typography variant='body2'>{props.value}</Typography>
    },
    {
      accessor: 'participantName',
      Header: t('LABEL.NAME'),
      Cell: props => <Typography variant='body2'>{props.value}</Typography>
    },
    {
      accessor: 'participantSurname',
      Header: t('LABEL.SURNAME'),
      Cell: props => <Typography variant='body2'>{props.value}</Typography>
    }
  ]

  const handleShowIncidentsDetail = idIncident => {
    const userSession = JSON.parse(sessionStorage.getItem('userSession'))
    if (userSession.userIdsRoles.find(idRole => idRole === 1 || idRole === 4)) {
      serviceIncidents.getIncidentsDetail(idIncident).then(response => {
        if (!response.error) {
          navigate(`/incidentsDetails`, {
            state: { data: response.data, type: 'UPDATE' }
          })
        }
      })
    } else {
      snackActions.error(t('TEXT.FORBIDDEN.USER.DONT_PERMISSION_ACTION'));
    }  
  }

  return (
    <div className={classes.container}>
      <HeaderTitle
        title={t('MENU_ADMIN.INCIDENTS')}
        subtitle={t('TEXT.SUBTITLE_INCIDENTS')}
      ></HeaderTitle>
      <DynamicTable
        columns={COLUMNS}
        endpoint='Incidents/List'
        isGlobalSearch={true}
        isColumnsSearch={true}
        isOrdered={true}
        orderColumn={ORDER}
      />
    </div>
  )
}

export default Incidents
