import React from 'react'
import AddButton from '@components/AddButton'
import DynamicTable from '@components/DynamicTable'
import HeaderTitle from '@components/HeaderTitle'
import { IconButton, Typography, Box, Tooltip } from '@mui/material'
import serviceCycleJourneys from '@services/serviceCycleJourneys'
import { useCommonStyles } from '@styles/common.style'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { EditRounded } from '@mui/icons-material'
import Moment from 'react-moment'
import { cycleToCycleType } from '@helpers/common'
import { snackActions } from '@helpers/snackbarUtils';

const JourneysSer = () => {
  const classes = useCommonStyles()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const modality = [
    { label: t('LABEL.ONSITE'), value: 1 },
    { label: t('LABEL.DIGITAL'), value: 2 }
  ]

  const getModality = value => {
    let res = modality.filter(x => x.value === value)
    return res.length ? res[0].label :''
  }

  const ORDER = [
    {
      orderField: 'cycleJourneyDateFrom',
      orderAsc: false
    }
  ]
  const COLUMNS = [
    {
      accessor: '',
      Header: 'Info',
      filterable: false,
      hideHeader: true,
      width: '5%',
      Cell: ({ row: { values } }) => (
        <Box display='flex' flexDirection='row'>
          <Tooltip title={t('LABEL.EDIT')}>
            <IconButton
              aria-label='info'
              color='icon_blue'
              style={{
                padding: '0px',
                marginRight: 15
              }}
              onClick={() => {
                handleShowJourneySerDetail({
                  idCycleJourney: values.idCycleJourney,
                  type: 'UPDATE'
                })
              }}
            >
              <EditRounded fontSize='inherit' />
            </IconButton>
          </Tooltip>
        </Box>
      )
    },
    {
      accessor: 'idCycleJourney',
      Header: t('LABEL.ID'),
      filterable: false,
      width: '5%'
    },
    {
      accessor: 'cycleName',
      Header: t('CYCLES.CYCLE_TYPE'),
      Cell: props => <Typography variant='body2'>{props.value}</Typography>
    },
    {
      accessor: 'cycleJourneyIdRecovery',
      Header: t('CYCLES.JOURNEYS.JOURNEY_RECOVERY'),
      
      Cell: props => <Typography variant='body2'>{props.value}</Typography>
    },
    {
      accessor: 'cycleJourneyDateFrom',
      Cell: props => <Moment format='DD/MM/YY HH:mm'>{props.value}</Moment>,
      Header: t('CYCLE_PARTICIPANTS.DATE')
    },
    {
      accessor: 'consultantName',
      Header: t('CONSULTANT.NAME'),
      Cell: props => <Typography variant='body2'>{props.value}</Typography>
    },
    {
      accessor: 'consultantSurname',
      Header: t('CONSULTANT.SURNAME'),
      Cell: props => <Typography variant='body2'>{props.value}</Typography>
    },
    {
      accessor: 'idCycleJourneyModality',
      Header: t('LABEL.MODALITY'),
      Cell: props => <Typography variant='body2'>{getModality(props.value)}</Typography>
    }
  ]
  
  const handleShowJourneySerDetail = ({ idCycleJourney, type }) => {
    const userSession = JSON.parse(sessionStorage.getItem('userSession'))
    if (userSession.userIdsRoles.find(idRole => idRole === 1 || idRole === 4)) {
      serviceCycleJourneys
        .getCycleJourneyDetails(idCycleJourney)
        .then(response => {
          if (!response.error) {
            navigate(`/journeySerDetail`, {
              state: {
                data: cycleToCycleType(response.data),
                type,
                idCycleJourney
              }
            })
          }
        })
    } else {
        snackActions.error(t('TEXT.FORBIDDEN.USER.DONT_PERMISSION_ACTION'));
    } 
  }

  const handleNewJourneySer = () => {
    const userSession = JSON.parse(sessionStorage.getItem('userSession'))
    if (userSession.userIdsRoles.find(idRole => idRole === 1 || idRole === 4)) {
        navigate(`/journeySerDetail`, {
          state: { data: { cycleJourneysParticipants: [] }, type: 'NEW' }
        })
    } else {
      snackActions.error(t('TEXT.FORBIDDEN.USER.DONT_PERMISSION_ACTION'));
    } 
  }
  return (
    <div className={classes.container}>
      <HeaderTitle
        title={t('MENU_ADMIN.JOURNEYSSER')}
        subtitle={t('TEXT.SUBTITLE_SER')}
      >
        <AddButton
          text={t('BUTTON.NEW_JOURNEY')}
          handleOnclick={handleNewJourneySer}
        />
      </HeaderTitle>
      <DynamicTable
        columns={COLUMNS}
        endpoint='JourneySer/List'
        isGlobalSearch={true}
        isColumnsSearch={true}
        isOrdered={true}
        viewExcel={true}
        nameExcel={t('MENU_ADMIN.JourneysSer')}
        titleBodyExcel={t('COMPANY.BODY_EXCEL')}
        orderColumn={ORDER}
      />
    </div>
  )
}

export default JourneysSer
