import { useSnackbar } from "notistack";
import React from "react";
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';

const InnerSnackbarUtilsConfigurator = (props) => {
    props.setUseSnackbarRef(useSnackbar());
    return null;
};

let useSnackbarRef;
const setUseSnackbarRef = (useSnackbarRefProp) => {
    useSnackbarRef = useSnackbarRefProp;
};

export const SnackbarUtilsConfigurator = () => {
    return (
        <InnerSnackbarUtilsConfigurator setUseSnackbarRef={setUseSnackbarRef} />
    );
};

export const snackActions = {
    success(msg) {
        this.toast(msg, "success");
    },
    warning(msg) {
        this.toast(msg, "warning");
    },
    info(msg) {
        this.toast(msg, "info"); 
    },
    error(msg) {
        this.toast(msg, "error");
    },
    toast(msg, variant = "default") {
        let customOptions = {};

        // Condición para cambiar la posición solo para notificaciones de tipo 'info'
        if (variant === 'info') {
          customOptions = {
            anchorOrigin: {
              vertical: 'top', // Cambia a 'top' si prefieres la parte superior
              horizontal: 'right', // Cambia a 'left' si prefieres la izquierda
            },
            autoHideDuration: null,
            action: (key) => (
                <IconButton onClick={() => useSnackbarRef.closeSnackbar(key)} color="inherit">
                  <CloseIcon />
                </IconButton>
              ),
          };
        }

        if (variant === 'warning') {
          customOptions = {
            anchorOrigin: {
              vertical: 'bottom', // Cambia a 'top' si prefieres la parte superior
              horizontal: 'left', // Cambia a 'left' si prefieres la izquierda
            },
            autoHideDuration: null,            
            action: (key) => (
                <IconButton 
                  onClick={() => useSnackbarRef.closeSnackbar(key)} 
                  color="inherit"
                  sx={{
                      position: 'absolute',
                      top: '8px', 
                      right: '8px' 
                  }}
                >
                  <CloseIcon />
                </IconButton>
              ),
          };
        }

        useSnackbarRef.enqueueSnackbar(msg, {
          variant,
          ...customOptions, // Fusiona las opciones personalizadas con el objeto de configuración
        });

        if (window.innerWidth <= 480 && variant !== 'warning' && variant !== 'info') setTimeout(() => {useSnackbarRef.closeSnackbar()}, 1500);
    },
};