import { Grid } from '@mui/material'
import React, { useState } from 'react'
import SectionForm from './SectionForm'
import SectionCycleJourneysTabs from '../cyclesJourneys/SectionCycleJourneysTabs'
import SectionInvited from './SectionInvited'
import SectionParticipants from './SectionParticipants'

const TabConfiguration = props => {
  const journeys = props.formData.cycleJourneys;
  const journeysTypeModeCodes = journeys != null ? journeys.map(journey =>  journey.idCycleJourneyModality ) : [0];
  const cycleTypeModeCode = props.formData.cycleTypeMode == 0 || props.formData.cycleTypeMode == undefined ? 1 : props.formData.cycleTypeMode;
  const [ journeyTypeModeLive, setJourneyTypeModeLive] = useState(journeysTypeModeCodes);
  const [ cycleTypeModeLive, setCycleTypeModeLive] = useState(cycleTypeModeCode);
  
  props = {
    ...props, 
    journeyTypeModeLive: journeyTypeModeLive, 
    setJourneyTypeModeLive: setJourneyTypeModeLive,
    cycleTypeModeLive: cycleTypeModeLive,
    setCycleTypeModeLive: setCycleTypeModeLive,
    journeysTypeModeCodes: journeysTypeModeCodes
  }
 
  //Quito SectionCompanies para ponerlo dentro de SectionForm
  return (
    <Grid container spacing={3} mt={2}> 
        <Grid item xs={12}>
          <SectionForm {...props} />
        </Grid>   
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xl={6} lg={12} md={12} sm={12} xs={12}>
              <SectionInvited {...props} />
            </Grid>
            <Grid item xl={6} lg={12} md={12} sm={12} xs={12}>
              <SectionParticipants {...props} />
            </Grid>
          </Grid>
        </Grid>
      <SectionCycleJourneysTabs {...props} />
    </Grid>
  );
  
}
  

export default TabConfiguration
