import { makeStyles } from '@mui/styles';

export const useCalendarStyles = makeStyles(theme => ({
    fullCalendar: {
        '& .fc': { 
            maxWidth: '1200px', //Para que a gran resolución no salga el scroll horizontal sin motivo
            margin: '0 auto'
        },
        '& .fc .fc-chrono > .fc-widget-header':  {
          backgroundColor: '#d7f4fc !important',
          color: '#000000',
        },
        '& .fc .fc-resource-area.fc-widget-content > .fc-scrollpane > div > .fc-scrollpane-inner': {
          backgroundColor: '#ffe992',
          color: '#000000',
        },
        '& .fc > .fc-resource-area > .fc-scrollpane > div': {
          overflowX: 'hidden!important',
        },
        '& .fc > .fc-resource-area > .fc-cell-text': {
            paddingLeft: '0px',
            paddingRight: '0px'
        },
        
        '& .fc > .fc-toolbar': {
          marginBottom: '0!important',
        },
        '& .fc .fc-toolbar-title': {
            textTransform: 'capitalize',
            margin: '0px 5px',
        },
        '& .fc .fc-toolbar-title::before': {
            content: `attr(data-content)`,
            display: 'inline-block',
            overflow: 'hidden',
            maxWidth: '50px', /* Ajusta el ancho máximo según tus necesidades */
            whiteSpace: 'nowrap', /* Evita que el texto se divida en varias líneas */
            textOverflow: 'ellipsis'
        },
        '& .fc > .fc-toolbar .fc-toolbar-chunk > div': {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center'
        },
        '& .fc .fc-button' : {
            width: '2em',
            height: '2em',
            backgroundColor: 'lightgrey',
            padding: '0px !important',
            fontWeight: 'bold !important' 
        },
        '& .fc .fc-datagrid-cell-cushion': {
            padding: '0px !important',
            backgroundColor: '#ffe992',
        },
        '& .fc .fc-timeline-header-row:last-child .fc-timeline-slot-frame': {
            backgroundColor: '#d7f4fc',
        },
        '& .fc-direction-ltr .fc-timeline-slot': {
            borderWidth: '0 2px'
        },
        '& .fc .fc-datagrid-header .fc-datagrid-cell-frame': {
            backgroundColor: 'white !important',
            height: '100%'
        },
        '& .fc .fc-datagrid-header .fc-datagrid-cell-frame > .fc-scrollgrid-sync-inner': {
            backgroundColor: 'white !important',
        },
        '& .fc-datagrid-cell-frame': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#ffe992',
            // maxHeight: 'auto',        
            // height: 'auto !important',
        },
        '& .fc > tbody > tr': {
            height: '25px',
            maxHeight: '25px'
        },
        'algo': {
            backgroundColor: 'red !important'
        },
        '& .fc-event': {
            padding: '0px !important',
            marginBottom: '0px',
            marginTop: '0px'
        },
        '& .fc-time-area .fc-widget-header': {
            height: '22px !important',
            overflow: 'hidden'
        },
        '& .timezone': {
            // color: 'red !important',
            fontSize: '0.8em'
        }
      },
}));