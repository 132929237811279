import { makeStyles } from '@mui/styles';

import Image from '@assets/images/background_login_participants.png';
export const useStyles = makeStyles(theme => {
    return {
        participants: {
            backgroundImage: `linear-gradient(90deg, rgba(4,139,197,0.6) 0%, rgba(117,206,224,0.9) 100%),url(${Image})`,
            backgroundSize: 'cover',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh !important',
            [theme.breakpoints.down('sm')]: {
                backgroundImage: 'none',
                alignItems: 'flex-start'
            }
        },

        card: {
            maxWidth: '450px',
            background: 'white',
            // padding: '1.5em 2em !important',
            // [theme.breakpoints.down('sm')]: {
            //     margin: '20px',
            //     padding: '40px 25px',
            // },
        },
        header: {
            height: '100%',
            width: '100%',
            textAlign: 'center',
            backgroundColor: '#0085B4',
            boxShadow: 'none !important'  ,
            maxWidth: '700px', 
            margin: '0px auto',
            zIndex:'99999'  ,
            padding: '0px !important'
        },
        title: {
            marginTop: '15px',
            marginBottom: '30px',
            color: theme.palette.primary.main,
            fontWeight: 'bold',
            fontSize: '1.5em'
        },
        subtitle: {
            margin: '10px 5px 10px 20px',
            color: theme.palette.primary.main,
            fontWeight: 'bold',
            fontSize: '1.5em'
        },
        subtitle2: {
            color: theme.palette.primary.main,
            fontWeight: 'bold',
            fontSize: '1.1em',
            textAlign: 'left'
        },
        image: {
            width: '45px',
            // [theme.breakpoints.down('sm')]: {
            //     width: '25%',
            // },
        },
        targets: {
            padding: '0.5em 1em !important',
            display: "flex",
            justifyContent: "flex-start", 
            alignItems: 'center',
            margin: '0px 0px 0px 10px !important',
            color: theme.palette.primary.main
        },
        checkbox: {
            width:'2em', 
            height:'2em',
            stroke: 'rgb(255,255,255) !important'           
        },
        padding0: {
            padding: '0px !important'
        },
        colorPrimary: {
            color: theme.palette.primary.main,
            borderColor: theme.palette.primary.main
        },
        colorSecondary: {
            color: theme.palette.secondary.main,
            borderColor: theme.palette.secondary.main
        },
        imageHeader: {
            objectFit:'contain',
            objectPosition:'center center',
            height:'auto',
            width: '100%',
            borderRadius: '3px 3px 0px 0px'   
        },
        btnsTab0: {
            backgroundColor:'#F7F7F7',
            cursor: 'default',
            color: '#0085B4 !important',
            borderColor: '#0085B4 !important'
        },
        btnsTab02: {
            backgroundColor:'#F7F7F7',
            cursor: 'default',
            color: '#e9a459 !important',
            borderColor: '#e9a459 !important'
        },
        btnSecondary: {
            backgroundColor: theme.palette.secondary.main,
            color: 'white',
            fontWeight: 'bold',
            fontSize: '1.1em',
        },
        btnSecondaryDisabled: {
            backgroundColor: 'rgba(240, 240, 240, 0.8) !important',
            color: 'white',
            fontWeight: 'bold',
            fontSize: '1.1em',
            opacity: '0.6',
            //textShadow: '1px 1px 2px rgba(0, 0, 0, 0.2)',  // Sombra difusa para el texto
        }
    };
});