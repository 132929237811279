import moment from 'moment'

const getNextJourney = cycle => {
  const date = new Date()
  date.setDate(date.getDate())
  var filtered = cycle.cycleJourneys.filter(
    cycleJourney => Date.parse(cycleJourney.cycleJourneyDateFrom) > date
  )
  if (filtered.length > 0) {
    return filtered[0]
  } else {
    return cycle.cycleJourneys[cycle.cycleJourneys.length - 1]
  }
}

export default getNextJourney
