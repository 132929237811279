import React, { useEffect, useState } from 'react'
import Card from '@components/Card'
import Dialog from '@components/Dialog'
import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded';
import DeletePopover from '@components/DeletePopover'
import { useNavigate } from 'react-router-dom'
import { snackActions } from '@helpers/snackbarUtils';
import VisibilityIcon from '@mui/icons-material/Visibility'
import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  Checkbox,
  ListItemText,
  Typography,
  Tooltip,
  IconButton
} from '@mui/material'
import { People, Paragliding } from '@mui/icons-material'
import TableParticipantsJourney from '../cycles/TableParticipantsJourney'
import { useTranslation } from 'react-i18next'
import serviceParticipants from '@services/serviceParticipants'
import serviceCycles from '@services/serviceCycles'
import serviceCycleJourneysParticipants from '@services/serviceCycleJourneysParticipants'
const ParticipantsCycleJourney = ({
  data,
  setData,
  journeyIndex,
  getNewJourneys,
  setFormData,
  formData,
  setShowCycleParticipants,
  setLoading
}) => {

  const { t } = useTranslation()
  const journey = data[journeyIndex]
  const [openTable, setOpenTable] = useState(false)
  const [participantsSelected, setParticipantsSelected] = useState()
  const navigate = useNavigate()

  useEffect(() => {
    setParticipantsSelected(journey.cycleJourneyParticipants)
  }, [journey.cycleJourneyParticipants])

  const handleShowParticipantDetail = ({idParticipant, type}) => {
    serviceParticipants.getParticipantsDetails(idParticipant).then(response => {
      if (!response.error) {
        localStorage.setItem('participantDetailData', JSON.stringify({ data: response.data, type }));

        const newWindow = window.open('/participantDetail', '_blank');
        
        if (!newWindow) {
          navigate('/participantDetail');
        }
      }
    });
  }

  const addParatrooper = async (values) =>{
    setLoading(true)
    const isInJourney = participantsSelected?.some(
      participant => participant.idParticipant === values
    )
    if(isInJourney){
      snackActions.error(t('TEXT.PARTICIPANT_IN_JOURNEY'));
    } else {
      const data = {
        journeyNumb:journey.cycleJourneyNumber,
        idCycleType: formData.idCycleType,
        idParticipant: values,
        cycleName: formData.cycleName,
        idCycle: formData.idCycle,
        idCycleJourney: journey.idCycleJourney,
        idCycleTypeSubtype: formData.idCycleTypeSubtype
      }
  
      const { error } = serviceCycleJourneysParticipants.addParticipantParatrooper(data)

      if (!error) {
          let {
              data,
              error,
          } = await serviceCycles.getCycleDetail(formData.idCycle, false);
    
          if (!error) {
              setFormData(data);
              };
          }
        setOpenTable(false);
        setLoading(false)
    }
  }
  

  const handleCheckBox = (id, value) => {
    const newParticipants = journey.cycleJourneyParticipants.map(participant =>
      participant.idParticipant == id
        ? { ...participant, cycleJourneyAssistance: value }
        : participant
    )
    const newJourneys = getNewJourneys(
      newParticipants,
      'cycleJourneyParticipants',
      journeyIndex
    )

    setData(newJourneys)
  }

  const removeParticipantFromList = id => {
    const newParticipants = journey.cycleJourneyParticipants.filter(
      participant => participant.idParticipant !== id
    )

    const newJourneys = getNewJourneys(
      newParticipants,
      'cycleJourneyParticipants',
      journeyIndex
    )

    setData(newJourneys)
  }

  const addParticipantsToJourney = () => {
    const newJourneys = getNewJourneys(
      participantsSelected,
      'cycleJourneyParticipants',
      journeyIndex
    )
    setData(newJourneys)
  }

  const removeParticipant = async participant => {
    setLoading(true)
    const cycleParticipant = formData.cycleParticipants.find(cycleParticipant => cycleParticipant.idParticipant === participant.idParticipant);

    if(cycleParticipant == null || cycleParticipant == undefined){
      const { error } = await serviceCycleJourneysParticipants.deleteCycleJourneyInvited(participant.idCycleJourneysParticipants)
      if (!error) {
        let {
            data,
            error,
        } = await serviceCycles.getCycleDetail(formData.idCycle, false);
  
        if (!error) {
            setFormData(data);
            };
        }
      setShowCycleParticipants(false)
    } else {
        if(participant.idCycleOrigin == null){
          const { error } = await serviceCycleJourneysParticipants.deleteCycleJourneyParticipant(participant.idCycleJourneysParticipants)
          if (!error) {
            let {
                data,
                error,
            } = await serviceCycles.getCycleDetail(formData.idCycle, false);
      
            if (!error) {
                setFormData(data);
                };
            }
          setShowCycleParticipants(false)
        } else  {
          const borrar = {
            idParticipant: participant.idParticipant,
            idCycleJourneyOrigin: participant.idCycleJourneyOrigin,
            idCycleJourney: participant.idCycleJourney,
            idCycle: formData.idCycle,
            idCycleJourneysParticipants: participant.idCycleJourneysParticipants
          }
          const { error } = await serviceCycleJourneysParticipants.deleteParticipantParatrooper(borrar)
          if (!error) {
            let {
                data,
                error,
            } = await serviceCycles.getCycleDetail(formData.idCycle, false);
      
            if (!error) {
                setFormData(data);
                };
            }
          setShowCycleParticipants(false)
        }        
    }
    setLoading(false)
  };

  const addAssistance = async (participant) =>{
    setLoading(true)

    participant.cycleJourneyAssistance = true

    const data = {
      idCycleJourneysParticipants: participant.idCycleJourneysParticipants,
      idCycleJourney: participant.idCycleJourney,
      idParticipant: participant.idParticipant,
      cycleJourneyParticipantRate: participant.cycleJourneyParticipantRate,
      cycleJourneyParticipantRemarks: participant.cycleJourneyParticipantRemarks,
      cycleJourneyPartipantIncident: participant.cycleJourneyParticipantIncident,
      cycleJourneyAssistance:true
    }

      const { error } = serviceCycleJourneysParticipants.updateCycleJourneParticipant(data)

      if (!error) {
          let {
              data,
              error,
          } = await serviceCycles.getCycleDetail(formData.idCycle, false);
    
          if (!error) {
              setFormData(data);
              };
          }
        setLoading(false)

  }

  return (
    <Card>
      {openTable && (
        <Dialog
          disabled
          open={openTable ? true : false}
          width='50%'
          height='75%'
          hasCloseBtn={false}
          title={t('CYCLES.SEARCH_PARTCIPANTS')}
          close={() => setOpenTable(false)}
          content={
            <TableParticipantsJourney
              addParatrooper= {addParatrooper}
            />
          }
          actions={
            <Box display='flex' width='100%' justifyContent='flex-end'>
              <Button
                sx={{ mr: 1 }}
                variant='contained'
                color='error'
                onClick={() => {
                  setOpenTable(false)
                }}
                aria-label='move selected left'
              >
                {t('BUTTON.CLOSE')}
              </Button>
            </Box>
          }
        />
      )}

      <Button
        variant='contained'
        onClick={() => setOpenTable(true)}
        sx={{
          textTransform: 'none'
        }}
        endIcon={<VisibilityIcon />}
      >
        {t('CYCLES.JOURNEYS.SHOW_PARTICIPANTS_LIST')}
      </Button>

      <List>
        {journey?.cycleJourneyParticipants.map((participant, index) => {
          const fullName =
            participant.participantName + ' ' + participant.participantSurname

          return (
            <Box key={participant.idParticipant}>
              <ListItem
                sx={{
                  bgcolor: 'theme.palette.primary.main',
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <ListItemText
                  primaryTypographyProps={{ fontSize: 14 }}
                  sx={
                    participant.idCycleOrigin && {
                      color: 'blue'
                    }
                  }
                  primary={
                    (fullName && participant?.idCycleJourneyOrigin !== null)
                    ? (
                        <Typography component='span' variant='body2' color='#0085B4'>
                        <Paragliding fontSize="extrasmall"/>   {fullName}
                        </Typography>
                      )
                    : fullName
                    ?(
                      <Typography
                        component='span'
                        variant='body2'
                        color='text.primary'
                      >
                        {fullName}
                      </Typography>
                      )
                    :(
                      <Typography
                        component='span'
                        variant='body2'
                        color='text.primary'
                      >
                        {t('CYCLES.NO_USER_DATA')}
                      </Typography>
                      )
                  }
                  secondary={
                    <React.Fragment>
                      {participant.participantEmail ? (
                        <Typography
                          sx={{ display: 'inline' }}
                          component='span'
                          variant='body2'
                          color='text.primary'
                        >
                          {participant.participantEmail}
                        </Typography>
                      ) : (
                        <Typography
                          sx={{ display: 'inline' }}
                          component='span'
                          variant='body2'
                          color='text.primary'
                        >
                          {t('CYCLES.NO_EMAIL_DATA')}
                        </Typography>
                      )}
                    </React.Fragment>
                  }
                />

                {/* Bot�n PERFIL */}
                {participant.participantEmail && (
                  <Button
                    sx={{ml: 'auto'}}
                    onClick={() => {
                      handleShowParticipantDetail({
                        idParticipant: participant.idParticipant,
                      })
                    }}
                  >
                    <People />
                  </Button>
                )}
                  {!participant.cycleJourneyAssistance ? (
                    <IconButton
                      aria-label="info"
                      color="sucess"
                      style={{
                        padding: '0px'
                      }}
                      onClick={() => {
                        addAssistance(participant)
                      }}
                    >
                      <AddBoxRoundedIcon fontSize="inherit" />
                    </IconButton>
                  ) : (
                    <DeletePopover
                      remove={() => removeParticipant(participant)}
                    />
                  )}
              </ListItem>
              <Divider />
            </Box>

          )
        })}
      </List>
    </Card>
  )
}

export default ParticipantsCycleJourney
