import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Moment from 'react-moment';

const Clock = () => {
    const [now, setNow] = useState(new Date());
    useEffect(() => {
        const timer = setInterval(() => {
            setNow(new Date());
        }, 1000);
        return () => clearInterval(timer);
    }, []);

    return (
        <div>
            <Typography variant='button' mr={1}>
                <Moment format='DD[ / ]MM[ / ]yyyy'>{now}</Moment>
            </Typography>
            <Typography variant='button' fontSize={14}>
                <Moment format='HH:mm:ss'>{now}</Moment>
            </Typography>
        </div>
    );
};

export default Clock;