import apiRequest from '@helpers/apiRequest'


const handleFileDownload = (response) => {
    const fileName = response.data.fileName;
    const linkSource = response.data.file;
    const tempLink = document.createElement('a');
    tempLink.href = linkSource;
    tempLink.download = fileName;
    tempLink.click();
};

const getExcelParticipants = (data) => {
    apiRequest('POST', 'File/Participants', data).then((response) => {
        handleFileDownload(response);
    });
};

const getPdfEvaluator = (data) => {
    return apiRequest('POST', "File/GenerateEvaluatorReport", data.idEvaluatorParticipant)
        .then((response) => {
            handleFileDownload(response);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

const sendPdfEvaluator = (data) => {
    return apiRequest('POST', "File/SendEvaluatorReport", data.idEvaluatorParticipant);
};

const getWordFeedback = (data) => {
    return new Promise((resolve, reject) => {
        apiRequest('POST', 'File/Feedback', data)
            .then((response) => {
                handleFileDownload(response);
                resolve(); // Resuelve la promesa después de iniciar la descarga
            })
            .catch((error) => {
                reject(error); // Rechaza la promesa en caso de error
            });
    });
};

const getDiploma = (idParticipant, idCycle) => {
    return new Promise((resolve, reject) => {
        apiRequest('POST', `File/Diploma/${idParticipant}/${idCycle}`, idParticipant, idCycle)
            .then((response) => {
                handleFileDownload(response);
                resolve(); // Resuelve la promesa después de iniciar la descarga
            })
            .catch((error) => {
                reject(error); // Rechaza la promesa en caso de error
            });
    });
};



// FILES

const getFilesAttachList = data => {
  return apiRequest('POST', 'File/List', data)
}
const getFilesAttachDetail = idFileAttach => {
  return apiRequest('GET', `File/Details/${idFileAttach}`)
}

const updateFilesAttach = data => {
  const { idFileAttach } = data
  return apiRequest('PUT', `File/Update/${idFileAttach}`, data)
}

const newFilesAttach = data => {
  return apiRequest('POST', 'File/New', data)
}

const deleteFilesAttach = data => {
  const idFileAttach = data.idFileAttach
  return apiRequest('DELETE', `File/Delete/${idFileAttach}`)
}
const serviceFiles = {
  getExcelParticipants,
  getWordFeedback,
  getDiploma,
  getFilesAttachList,
  getFilesAttachDetail,
  updateFilesAttach,
  newFilesAttach,
  deleteFilesAttach,
  getPdfEvaluator,
  sendPdfEvaluator
}

export default serviceFiles
