import React, { useState } from 'react'
import AddButton from '@components/AddButton'
import DynamicTable from '@components/DynamicTable'
import HeaderTitle from '@components/HeaderTitle'
import { EditRounded } from '@mui/icons-material'
import { Box, IconButton, Tooltip, Typography } from '@mui/material'
import serviceCycles from '@services/serviceCycles'
import { useCommonStyles } from '@styles/common.style'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Moment from 'react-moment'
import { snackActions } from '@helpers/snackbarUtils'
import Loading from '@components/Loading'

const Cycles = () => {
    const classes = useCommonStyles()
    const navigate = useNavigate()
  const { t } = useTranslation()
    const [loading, setLoading] = useState(false)
    const modality = [
    { label: t('LABEL.ONSITE'), value: '1' },
    { label: t('LABEL.DIGITAL'), value: '2' },
    { label: t('LABEL.MIXED'), value: '1,2' }
    ]
    const getModality = value => {
        let res = modality.filter(x => x.value === value)
        return res.length ? res[0].label : t('LABEL.MIXED')
    }

    const filterMap = value => {
        if (value === '') return ''
        let res = modality.filter(x =>
            x.label.toLowerCase().includes(value.toLowerCase())
        )
        return res.length ? res[0].value : t('LABEL.MIXED')
    }

    sessionStorage.removeItem('details')
    
    const ORDER = [
        {
            orderField: 'cycleDateFrom',
            orderAsc: false
        }
    ]
    const COLUMNS = [
        {
            accessor: '',
            Header: 'Info',
            filterable: false,
            hideHeader: true,
            width: '5%',
      Cell: ({ row: { values } }) => (
                <Box display='flex' flexDirection='row'>
                    <Tooltip title={t('LABEL.EDIT')}>
                        <IconButton
                            aria-label='info'
                            color='icon_blue'
                            style={{
                                padding: '0px',
                                marginRight: 15
                            }}
                            onClick={() => {
                                handleShowCycleDetail({
                                    idCycle: values.idCycle,
                                    type: 'UPDATE'
                                })
                            }}
                        >
                            <EditRounded fontSize='inherit' />
                        </IconButton>
                    </Tooltip>
                </Box>
            )
        },
        {
            accessor: 'cycleName',
            Header: t('LABEL.NAME'),
            Cell: props => <Typography variant='body2'>{props.value}</Typography>,
            width: '20%'
        },
        {
            accessor: 'companyName',
            Header: t('LABEL.COMPANY'),
            Cell: props => <Typography variant='body2'>{props.value}</Typography>,
            width: '20%'
        },
        {
            accessor: 'cycleDateFrom',
            Header: t('LABEL.DATE_INIT'),
            Cell: props => (
                <Typography variant='body2'>
                    <Moment format='DD[/]MM[/]yyyy'>{props.value}</Moment>
                </Typography>
            ),
            width: '5%'
        },
        {
            accessor: 'cycleTypeCode',
            Header: t('TEXT.CYCLE_TYPE'),
            Cell: props => <Typography variant='body2'>{props.value}</Typography>,
            width: '5%'
        },
        {
            accessor: 'cycleModalities',
            Header: t('LABEL.MODALITY'),
            Cell: props => (
                <Typography variant='body2'> {getModality(props.value)}</Typography>
            ),
            width: '5%',
            searchMode: 'EqualTo',
            filterMap: x => filterMap(x)
        },
        {
            accessor: 'idCycle',
            Header: t('LABEL.ID'),
            filterable: true,
            width: '5%',
            hiddenColumn: false
        },

    ]

  const handleShowCycleDetail = async ({ idCycle, type }) => {
        //Se verifica si el usuario tiene permisos para realizar las acciones sobre un ciclo
        const userSession = JSON.parse(sessionStorage.getItem('userSession'))
        if (userSession.userIdsRoles.find(idRole => idRole === 1 || idRole === 4)) {
            setLoading(true)
      const { data, error } = await serviceCycles.getCycleDetail(idCycle, true)
            if (!error) {
                navigate(`/cycleDetail`, {
                    state: { data, type }
                })
            } else {
                setLoading(false)
            }
        } else {
            setLoading(false)
            snackActions.error(t('TEXT.FORBIDDEN.USER.DONT_PERMISSION_ACTION'))
        }
    }
    const handleNewCycle = async () => {
        //Se verifica si el usuario tiene permisos para realizar las acciones sobre un ciclo
        const userSession = JSON.parse(sessionStorage.getItem('userSession'))
        if (userSession.userIdsRoles.find(idRole => idRole === 1 || idRole === 4)) {
      navigate('/cycleDetail', { state: { type: 'NEW' } })
        } else {
            snackActions.error(t('TEXT.FORBIDDEN.USER.DONT_PERMISSION_ACTION'))
        }
    }

    if (loading) {
        return <Loading />
    }
    return (
        <div className={classes.container}>
            <HeaderTitle
                title={t('MENU_ADMIN.CYCLES')}
                subtitle={t('TEXT.SUBTITLE_CYCLES')}
            >
                <AddButton
                    text={t('BUTTON.NEW_CYCLE')}
                    handleOnclick={handleNewCycle}
                />
            </HeaderTitle>
            <DynamicTable
                columns={COLUMNS}
                endpoint='Cycles/List'
                isGlobalSearch={true}
                isColumnsSearch={true}
                isOrdered={true}
                viewExcel={true}
                nameExcel={t('MENU_ADMIN.CYCLES')}
                titleBodyExcel={t('CYCLES.BODY_EXCEL')}
                orderColumn={ORDER}
            />
        </div>
    )
}

export default Cycles
