import moment from 'moment'

const formatEvents = (events, setEvents, categoriesList) => {
    const categoryListBlocked = [10,19,20,24]
    const { idUser} = JSON.parse(sessionStorage.getItem('userSession'))   

    if (events?.length > 0 && categoriesList.length > 0) {
        const tieneResourceIds = events.some(event => event.hasOwnProperty("resourceId"));
        const tieneTimeText = events.some(event => event.hasOwnProperty("timeText"));
        const tieneStart = events.some(event => event.hasOwnProperty("start"));
        const tieneEnd = events.some(event => event.hasOwnProperty("end"));
        if (!tieneResourceIds || !tieneTimeText || !tieneStart || !tieneEnd) {
            const newArrayEvents = events.map(event => {
                const resourceIdFrom = event.eventDateFrom.slice(8, 10);
                const resourceIdTo = event.eventDateTo.slice(8, 10);
                // Esto mira si el día de inicio es igual al de fin. Si es igual devuelve un string y si son varios un array
                const resourceIds = resourceIdFrom === resourceIdTo 
                    ? resourceIdFrom 
                    : Array.from({ length: parseInt(resourceIdTo, 10) - parseInt(resourceIdFrom, 10) + 1 }, (_, index) => (parseInt(resourceIdFrom, 10) + index).toString());       
                
                const fechaOriginal = new Date(event.eventDateFrom);
                fechaOriginal.setDate(1);
                const year = fechaOriginal.getFullYear();
                const month = String(fechaOriginal.getMonth() + 1).padStart(2, '0');
                const day = String(fechaOriginal.getDate()).padStart(2, '0');
                const hours = String(fechaOriginal.getHours()).padStart(2, '0');
                const minutes = String(fechaOriginal.getMinutes()).padStart(2, '0');
                const seconds = String(fechaOriginal.getSeconds()).padStart(2, '0');
                const nuevaFecha = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
    
                const horaMinutosEventDateTo = new Date(event.eventDateTo);
                const hoursEnd = String(horaMinutosEventDateTo.getHours()).padStart(2, '0');
                const minutesEnd = String(horaMinutosEventDateTo.getMinutes()).padStart(2, '0');
                const secondsEnd = String(horaMinutosEventDateTo.getSeconds()).padStart(2, '0');
                const end = `${year}-${month}-${day}T${hoursEnd}:${minutesEnd}:${secondsEnd}`;

                const matchingCategory = categoriesList.find(
                    (category) => category.idCategory === event.idCategory
                );
                
                var ini = moment(nuevaFecha).valueOf();
                var endf = moment(end).valueOf();

                const timePreBlocked = event.eventTimePreBlocked * 60000
                const timePostBlocked = event.eventTimePostBlocked * 60000
                const newIni = moment(ini - timePreBlocked).format('YYYY-MM-DDTHH:mm:ss');
                const newEnd = moment(endf + timePostBlocked).format('YYYY-MM-DDTHH:mm:ss');
                
        
                // Si la categoría no viene lo ponemos como null para filtrar los eventos que no tengan categoría para el idioma en el que estamos.
                if (matchingCategory == null || matchingCategory == undefined || matchingCategory == 'undefined') return null;

                const newStart = (e) => {
                    if (e.idCategory === 25) {
                        return ''
                    } else {
                        if (e.eventTimePreBlocked !== 0) {
                            return newIni
                        } else {
                            return nuevaFecha
                        }
                    }
                }

                const newEnd1 = (e) => {
                    if (e.eventTimePostBlocked !== 0) {
                        return newEnd
                    } else {
                        return end
                    }
                }
                // Modificar el color basado en la correspondiente entrada en categoriesList
                
                if (Array.isArray(resourceIds)){
                    if (matchingCategory) {
                        return { ...event, 
                            resourceIds: resourceIds,
                            start:  newStart(event),
                            end: newEnd1(event),
                            timeText: hours+':'+minutes,
                            /*color: event.eventColor !== null && event.eventColor !== '' ? event.eventColor: matchingCategory.color,
                            eventColor: event.eventColor !== null && event.eventColor !== '' ? event.eventColor : matchingCategory.color,*/
                            color: event.idCategory === 25 ? 'transparent' : matchingCategory.color,
                            eventColor: matchingCategory.color,
                            eventFontColor: matchingCategory.fontColor,
                            icon: matchingCategory.icon,
                            idUsers: [event.idUser],
                            startEditable: (categoryListBlocked.includes(event.idCategory) || event.idUser !== idUser) ? false : true,
                            durationEditable: (categoryListBlocked.includes(event.idCategory) || event.idUser !== idUser) ? false : true,
                            resourceEditable: (categoryListBlocked.includes(event.idCategory) || event.idUser !== idUser) ? false : true
                        }
                    };
                } else {
                    if (matchingCategory) {
                        return { ...event, 
                            resourceId: resourceIds,
                            start:  newStart(event),
                            end: newEnd1(event),
                            timeText: hours+':'+minutes,
                            /*color: event.eventColor !== null && event.eventColor !== '' ? event.eventColor: matchingCategory.color,
                            eventColor: event.eventColor !== null && event.eventColor !== '' ? event.eventColor : matchingCategory.color,*/
                            color: event.idCategory === 25 ? 'transparent' : matchingCategory.color,
                            eventColor: matchingCategory.color,
                            eventFontColor: matchingCategory.fontColor,
                            icon: matchingCategory.icon,
                            idUsers: [event.idUser],
                            startEditable: (categoryListBlocked.includes(event.idCategory) ||  event.idUser !== idUser) ? false : true,
                            durationEditable: (categoryListBlocked.includes(event.idCategory) ||  event.idUser !== idUser) ? false : true,
                            resourceEditable: (categoryListBlocked.includes(event.idCategory) ||  event.idUser !== idUser) ? false : true
                        };
                    }
                }
            }).filter(event => event !== null); // Eliminar eventos que sean null;
          
            setEvents(newArrayEvents);
        }
    }
}

export default formatEvents