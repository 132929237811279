import React, { Fragment, useState, useEffect } from 'react'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import {
  Box,
  Chip,
  Grid,
  MenuItem,
  Select,
  Typography,
  TextField
} from '@mui/material'
import { useCommonStyles } from '@styles/common.style'
import { useTranslation } from 'react-i18next'
import DeletePopover from '@components/DeletePopover'

const TargetConfiguration = ({
  journey,
  targetList,
  getNewJourneys,
  formData,
  setFormData,
  isUpdate,
  index
}) => {
  const classes = useCommonStyles()
  const { t } = useTranslation()
  const [maxTargets, setMaxTargets] = useState(
    journey?.cycleTypeJourneyTargetsNum
  )
  const [subObjList, setsubObjList] = useState()
  useEffect(() => {
    const filteredList = journey.cycleTypeJourneyTargets.map(selectedTarget => {
      return targetList.find(itemTargetList => {
        return itemTargetList.idTarget === selectedTarget.idTarget
      })
    })
    setsubObjList([
      {
        idLanguage: 1,
        idTarget: 99,
        idTargetLocale: 99,
        targetLocaleName: t('TEXT.NO_ELEMENTS')
      },
      ...new Set(filteredList.filter(item => item))
    ])
  }, [journey.cycleTypeJourneyTargets, targetList])

  const handleChangeConfiguration = e => {
    const data = Number(e.target.value)
    setMaxTargets(data)
    if (data <= journey?.cycleTypeJourneyTargets.length) {
      const newJourneys = getNewJourneys(data, e.target.name)
      setFormData(prevState => {
        return { ...prevState, cycleTypeJourneys: newJourneys }
      })
    }
  }

  const handleChangeTargets = (e, journey, index) => {
    const newTargetValues = journey.cycleTypeJourneyTargets.map((target, i) => {
      if (i === index) {
        return {
          ...target,
          [e.target.name]: e.target.value
        }
      }
      return target
    })
    resetSelect(newTargetValues)
    const newJourneys = getNewJourneys(
      newTargetValues,
      'cycleTypeJourneyTargets'
    )
    setFormData({
      ...formData,
      cycleTypeJourneys: newJourneys
    })
  }
  const addTarget = item => {
    const newTargetValues = [
      ...item.cycleTypeJourneyTargets,
      {
        idTarget: targetList[0].idTarget,
        targetIdParent: 99,
        ...(isUpdate && { idCycleTypeJourney: item.idCycleTypeJourney })
      }
    ]

    const newJourneys = getNewJourneys(
      newTargetValues,
      'cycleTypeJourneyTargets'
    )
    setFormData({
      ...formData,
      cycleTypeJourneys: newJourneys
    })
  }

  const removeTarget = (journey, targetIndex) => {
    const newTargetValues = journey.cycleTypeJourneyTargets.filter(
      (_, index) => index !== targetIndex
    )

    resetSelect(newTargetValues)

    const newJourneys = getNewJourneys(
      newTargetValues,
      'cycleTypeJourneyTargets'
    )

    setFormData({
      ...formData,
      cycleTypeJourneys: newJourneys
    })

    //Si el tamaño de objetivos a cumplir es mayor a los objetivos para dicho tipo de ciclo(al haber eliminado uno)
    //Actualizamos el máximo de objetivos a cumplir y el cycleTypeJourneys
    if (maxTargets > newJourneys[0].cycleTypeJourneyTargets.length) {
      setMaxTargets(newJourneys[0].cycleTypeJourneyTargets.length)
    }
  }

  const resetSelect = newTargetValues => {
    //Array con los valores que están en el listado de objetivos
    const permittedValues = newTargetValues.map(value => value.idTarget)
    //Si en listado de subobjetivos hay un objetivo eliminado se cambia al valor a 99 = Ninguno
    newTargetValues.forEach(value => {
      if (!permittedValues.includes(value.targetIdParent)) {
        value.targetIdParent = 99
      }
    })
  }

  return (
    <Fragment>
      <Typography
        textAlign='center'
        color='white'
        variant='subtitle1'
        mb={2}
        className={classes.cardTitle}
      >
        {t('CONFIG_JOURNEYS.TARGET.CONFIG_TARGET').toUpperCase()}
      </Typography>

      {journey.cycleTypeJourneyTargets.length !== 0 ? (
        <Grid container spacing={2}>
          <Grid item xs={5.5}>
            <Typography variant='caption' component='div'>
              {t('CONFIG_JOURNEYS.TARGET.TARGET')}
            </Typography>
          </Grid>
          <Grid item xs={5.5}>
            <Typography variant='caption' component='div'>
              {t('CONFIG_JOURNEYS.TARGET.SUBTARGET')}
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <Typography variant='caption' component='div'>
          {t('CONFIG_JOURNEYS.TARGET.NO_ASSIGNED_TARGETS')}
        </Typography>
      )}

      {journey.cycleTypeJourneyTargets.map((target, i) => {
        return (
          <Grid container spacing={1} mb={1} key={i}>
            <Grid item xs={5.5}>
              <Select
                className={classes.select}
                fullWidth
                size='small'
                name='idTarget'
                value={target.idTarget}
                onChange={e => handleChangeTargets(e, journey, i)}
              >
                {targetList.map(target => {
                  return (
                    <MenuItem
                      value={target.idTarget}
                      key={target.idTarget}
                      className={classes.menuItem}
                    >
                      {target.targetLocaleName}
                    </MenuItem>
                  )
                })}
              </Select>
            </Grid>

            <Grid item xs={5.5}>
              {subObjList && (
                <Select
                  className={classes.select}
                  fullWidth
                  size='small'
                  name='targetIdParent'
                  value={target?.targetIdParent}
                  onChange={e => handleChangeTargets(e, journey, i)}
                >
                  {subObjList.map(
                    subObj =>
                      subObj && (
                        <MenuItem
                          value={subObj.idTarget}
                          key={subObj.idTarget}
                          className={classes.menuItem}
                        >
                          {subObj.targetLocaleName}
                        </MenuItem>
                      )
                  )}
                </Select>
              )}
            </Grid>

            <Grid item xs={1}>
              <Box
                style={{
                  height: '100%',
                  alignItems: 'center',
                  display: 'flex'
                }}
              >
                <DeletePopover
                  remove={() => {
                    removeTarget(journey, i)
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        )
      })}
      <Box display='flex' justifyContent='start' mt={2}>
        <Chip
          size='small'
          onClick={() => {
            addTarget(journey)
          }}
          label={t('CONFIG_JOURNEYS.TARGET.ADD')}
          color='primary'
          sx={{ padding: 0 }}
          icon={<AddCircleIcon />}
        />
      </Box>
      <Box my={2}>
        <Typography variant='caption' component='div'>
          {t('CONFIG_JOURNEYS.TARGET.MINIMUN_OBJ')}
        </Typography>
        <TextField
          placeholder='0'
          type='number'
          name='cycleTypeJourneyTargetsNum'
          size='small'
          value={maxTargets || ''}
          inputProps={{
            inputMode: 'numeric',
            min: 0,
            max: journey?.cycleTypeJourneyTargets.length
          }}
          onChange={e => {
            handleChangeConfiguration(e, journey)
          }}
        />
      </Box>
    </Fragment>
  )
}

export default TargetConfiguration
