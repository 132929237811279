import { makeStyles } from '@mui/styles';
import Image from '@assets/images/background_login_scheduler.jpg';
export const useStyles = makeStyles(theme => {
    return {
        loginScheduler: {
            backgroundImage: `linear-gradient(90deg, rgba(4,139,197,0.1) 0%, rgba(117,206,224,0.4) 100%),url(${Image})`,
            backgroundSize: 'cover',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
        },

        card: {
            maxWidth: '450px',
            background: 'white',
            padding: '70px 50px',
            [theme.breakpoints.down('sm')]: {
                margin: '20px',
                padding: '40px 25px',
            },
        },
        header: {
            textAlign: 'center',
        },
        title: {
            margin: '25px 0',
            color: theme.palette.secondary.main,
            fontWeight: 'bold',
        },
        subtitle: {
            margin: '0px 5px'
        },
        image: {
            width: '30%',
            [theme.breakpoints.down('sm')]: {
                width: '30%',
            },
        },
        secondImage: {
            width: '55%',
            [theme.breakpoints.down('sm')]: {
                width: '240px',
            },
            margin: '10px'
        }
    };
});