import apiRequest from '@helpers/apiRequest'

const getParticipants = data => {
  return apiRequest('POST', 'Participants/List', data)
}
const getParticipantsDetails = idParticipant => {
  return apiRequest('GET', `Participants/Details/${idParticipant}`)
}

const updateParticipant = data => {
  const idParticipant = data.idParticipant
  return apiRequest('PUT', `Participants/Update/${idParticipant}`, data)
}

const newParticipant = data => {
  return apiRequest('POST', 'Participants/New', data)
}

const deleteParticipant = data => {
  const idParticipant = data.idParticipant
  return apiRequest('DELETE', `Participants/Delete/${idParticipant}`)
}

const signUpParticipant = data => {
  const idCycle = data.idCycle
  return apiRequest('POST', `Participants/SignUp/${idCycle}`, data.data)
}

const checkParticipants = data => {
  return apiRequest('POST', 'Login/CheckUser', data)
}

const serviceParticipants = {
  getParticipants,
  getParticipantsDetails,
  updateParticipant,
  newParticipant,
  deleteParticipant,
  signUpParticipant,
  checkParticipants
}

export default serviceParticipants
