import Card from '@components/Card'
import BackspacePopover from '@components/BackspacePopover'
import Dialog from '@components/Dialog'
import getNextJourney from '@helpers/nextJourney'
import { useCommonStyles } from '@styles/common.style'
import {
  Box,
  Button,
  ButtonGroup,
  IconButton,
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Typography,
  ListItemIcon,
  Checkbox,
  TextField,
  Grid
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import Icon from '@components/Icon'
import { useTranslation } from 'react-i18next'
import AddIcon from '@mui/icons-material/Add'
import SwapHorizIcon from '@mui/icons-material/SwapHoriz'
import TableTemplateMailing from '@components/TableTemplateMailing'
import serviceRabbitMq from '@services/serviceRabbitMq'
import { snackActions } from '@helpers/snackbarUtils'
import serviceCycles from '@services/serviceCycles'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import Paragliding from '@mui/icons-material/Paragliding'
import { styled } from '@mui/material/styles'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import TableParticipants from './TableParticipants'
import TableCycleActive from './TableCycleActive'

const SectionParticipants = ({
  formData,
  setFormData,
  isUpdate,
  disabledSaveButton
}) => {
  const { t } = useTranslation()
  const [participantsSelected, setParticipantsSelected] = useState([])
  const [participantChange, setParticipantChange] = useState()
  const [showTemplatesDialog, setShowTemplatesDialog] = useState(false)
  const [loadingParticipants, setLoadingParticipants] = useState(false)
  const [participantChecked, setParticipantChecked] = useState([])
  const [allCheked, setAllChecked] = useState(false)
  const [mailData, setMailData] = useState([])
  const [listReload, setListReload] = useState(0)
  const classesCommon = useCommonStyles()
  const patternEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
  const [openTable, setOpenTable] = useState(false)
  const [showParticipantsDialog, setShowParticipantsDialog] = useState(false)
  const [showChangeDialog, setShowChangeDialog] = useState(false)
  const [loadingParticipantId, setLoadingParticipantId] = useState(null)

  const lastJourneyDateFrom =
    formData.cycleJourneys !== null && formData.cycleJourneys !== undefined && formData.cycleJourneys !== 'undefined'
      ? new Date(formData.cycleJourneys[formData.cycleJourneys?.length - 1]?.cycleJourneyDateTo) >= new Date()
      : false

  const handleCheckbox = e => {
    if (!e.target.checked) {
      const quitParticipantCheck = participantChecked.filter(
        participant => participant !== e.target.value
      )
      setParticipantChecked(quitParticipantCheck)
    } else {
      const newParticipantCheck = [...participantChecked, e.target.value]
      setParticipantChecked(newParticipantCheck)
    }
  }

  const checkList = participant => {
    const checkItems =
      participantChecked.indexOf(participant) !== -1 ? true : false
    return checkItems
  }

  const handleAllCheck = e => {
    if (!e.target.checked) {
      setParticipantChecked([])
    } else if (e.target.checked) {
      const tempArray = []
      participantsSelected.forEach(participant => {
        tempArray.push(participant.participantEmail)
      })
      setParticipantChecked(tempArray)
    }
    setListReload(Math.random())
  }

  useEffect(() => {
    setParticipantsSelected(formData.cycleParticipants)
  }, [formData.cycleParticipants])

  useEffect(() => {
    if (participantsSelected.length > 0)
      setAllChecked(
        participantChecked.length === participantsSelected.length ? true : false
      )
  }, [participantChecked])

  const removeParticipant = async (participant) => {
    setLoadingParticipantId(participant.idParticipant) // Guardar el ID del participant que se está procesando
    const journey = getNextJourney(formData)
    const journeysParticipant = formData.cycleJourneys.map(journey => ({
      idCycleJourney: journey.idCycleJourney,
      cycleJourneyNumber: journey.cycleJourneyNumber,
      cycleJourneyParticipants: journey.cycleJourneyParticipants
        .filter(participantJ => participant.idParticipant === participantJ.idParticipant)
    }))
  
    const participantData = {
      participant: participant,
      journey: journey.cycleJourneyNumber,
      idCycleNew: 0,
      journeys: journeysParticipant
    }

    const { error } = await serviceCycles.deleteCycleParticipant( participantData )

    if (!error) {
        let { data, error } = await serviceCycles.getCycleDetail(formData.idCycle, false)
        if (!error) { 
            setFormData(data)
            snackActions.success(t('CYCLES.DELETE_PARTICIPANT'))
        }
    } else {
        snackActions.error(t('CYCLES.ERROR_DELETE_PARTICIPANT'))
    }

    setLoadingParticipantId(null)
  }

  const handleChangeCycleDialog = (participant) => {
    if(!formData.hasEvaluator || (participant.totalReceiverResponses == 0 && participant.isAutoevalDone == null)){
      const journey = getNextJourney(formData)
      participant = {
        participant: participant,
        journey: journey.cycleJourneyNumber,
        cycleType:formData.idCycleType
      }
      setParticipantChange(participant)
      setShowChangeDialog(true)
    } else {
      snackActions.error(t('CYCLES.HAS_EVALUATOR'))
    }
  }
  
  const handleChangeCycle = async (idCycle) => {
    setLoadingParticipants(true)
    const journeysParticipant = formData.cycleJourneys.map(journey => ({
      idCycleJourney: journey.idCycleJourney,
      cycleJourneyNumber: journey.cycleJourneyNumber,
      cycleJourneyParticipants: journey.cycleJourneyParticipants
        .filter(participant => participantChange.participant.idParticipant === participant.idParticipant)
    }))
  
    const participant = {
      ...participantChange,
      idCycleNew: idCycle,
      journeys: journeysParticipant
    }

    const { error } = await serviceCycles.changeCycleParticipant( participant )

  if (!error) {
      let {
          data,
          error,
      } = await serviceCycles.getCycleDetail(formData.idCycle, false)

      if (!error) {
          setFormData(data)
          }
      }
    setLoadingParticipants(false)
    setShowChangeDialog(false)
  }

  const sendEmail = () => {
    if (participantChecked.length > 0) {
      const data = {
        idCycle: formData.idCycle,
        typeMail: 3,
        mails: participantChecked,
        locale: [
          {
            idLanguage: 1,
            mailingBody: '',
            mailingSubject: ''
          }
        ]
      }
      setMailData(data)
      setShowTemplatesDialog(true)
    } else {
      snackActions.error(t('CYCLES.JOURNEYS.SELECT_PARTICIPANT'))
    }
  }



  /*const sendEmailRrhh = () => {
    const idCompany = formData.idCompany
    const companyContact = formData.cycleCompanies?.find(
      x => x.idCompany === idCompany
    )
    const emailCompanyContact = companyContact.companyEmail
    const idCycle = formData.idCycle

    serviceRabbitMq.getMailRRHH(idCycle).then(response => {
      if (!response.error) {
        const data = {
          idCycle: idCycle,
          typeMail: 12,
          mails: [emailCompanyContact],
          locale: response.data.locales
        }
        setMailData(data)
        setShowTemplatesDialog(true)
      }
    })
  }*/

  //TODO: Unificar criterios, es posible que se estén haciendo mal algunas cosa
  const sendEmailParticipants = () => {
    let invalidMailFound = false
      mailData.mails.forEach((mail) => {
      if (mail === '' || !patternEmail.test(mail.toLowerCase())) {
        invalidMailFound = true
      }
    })

    if (invalidMailFound) {
      snackActions.error(t('LOGIN.SIGN_UP.ERROR.TYPE_EMAIL'))
    } else {
      if (mailData.typeMail === 3) {
        setShowTemplatesDialog(false)
        const data = {
          ...mailData,
          MailTo: participantChecked,
          Locales: mailData.locale
        }
        serviceRabbitMq.sendDirectMail(data).then(response => {
          if (!response.error) {
              setShowTemplatesDialog(false)
          }
        })
      } else if (mailData.typeMail === 12) {
        const data = {
          MailTo: mailData.mails,
          Locales: mailData.locale
        }
          setShowTemplatesDialog(false)
          serviceRabbitMq.sendMailRRHH({
            idCycle: formData.idCycle,
            to: data
          })
        
      } else {
        setShowTemplatesDialog(false)

        const data = {
          ...mailData,
          mails: mailData.mails,
          typeSendTo: 0,
          locales: mailData.locale
        }
        serviceRabbitMq.sendCallOrReminder(data)
      }
    }
  }

  //OVERRIDES ACCORDION
  const Accordion = styled(props => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0
    },
    '&:before': {
      display: 'none'
    }
  }))

  const AccordionSummary = styled(props => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, .05)'
        : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)'
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1)
    }
  }))

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)'
  }))

  const [expanded, setExpanded] = React.useState('panel0')

  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  return (
    <>
      <Card>
        <Dialog
          disabled
          open={showChangeDialog}
          width='30%'
          height='70%'
          hasCloseBtn={false}
          title={t('TEXT.SUBTITLE_CYCLES')}
          close={() => setShowChangeDialog(false)}
          content={
            <TableCycleActive
              handleChangeCycle={handleChangeCycle}
              participant={participantChange}
            />
          }
          actions={
            <Box display='flex' width='100%' justifyContent='flex-end'>
                <Button
                  variant='contained'
                  onClick={() => setShowChangeDialog(false)}
                  color='error'
                >
                  {t('BUTTON.CANCEL')}
                </Button> 
            </Box>
          }
        />

        {openTable && (
          <Dialog
              disabled
              open={openTable ? true : false}
              width='50%'
              height='75%'
              hasCloseBtn={false}
              title={t('CYCLES.SEARCH_PARTCIPANTS')}
              close={() => setOpenTable(false)}
              content={
                <TableParticipants
                    setParticipantsSelected={setParticipantsSelected}
                    participantsSelected={participantsSelected}
                />
              }
              actions={
                  <Box display='flex' width='100%' justifyContent='flex-end'>
                      <Button
                          sx={{ mr: 1 }}
                          variant='contained'
                          color='error'
                          onClick={() => {
                              setOpenTable(false)
                          }}
                          aria-label='move selected left'>
                          {t('CYCLES.OUT')}
                      </Button>
                      <Button
                          variant='contained'
                          color='success'
                          onClick={() => {
                              setFormData(prevState => {
                                  return {
                                      ...prevState,
                                      cycleParticipants: participantsSelected,
                                  }
                              })
                              setOpenTable(false)
                          }}
                          aria-label='move selected left'>
                          {t('CYCLES.SAVE_FORM_CHANGES')}
                      </Button>
                  </Box>
                }
              />
            )}

        {showTemplatesDialog && (
          <Dialog
            disabled={loadingParticipants}
            width='70%'
            height='80%'
            open={showTemplatesDialog}
            hasCloseBtn={false}
            title={t('CYCLES.SEND_MAIL')}
            close={() => {
              setShowTemplatesDialog(false)
              setMailData('')
            }}
            content={
              <TableTemplateMailing
                mailData={mailData}
                setMailData={setMailData}
                participantChecked={participantChecked}
                disabled={false}
              />
            }
            actions={
              <Box display='flex' width='100%' justifyContent='flex-end'>
                <Button
                  disabled={loadingParticipants}
                  sx={{ mr: 1 }}
                  variant='contained'
                  color='error'
                  onClick={() => {
                    setShowTemplatesDialog(false)
                  }}
                  aria-label='move selected left'
                >
                  {t('BUTTON.CLOSE')}
                </Button>
                <Button
                  variant='contained'
                  onClick={() => sendEmailParticipants()}
                  aria-label='move selected left'
                  disabled={mailData?.length == 0}
                >
                  {loadingParticipants ? (
                    <CircularProgress size={10} color='white' />
                  ) : (
                    t('CYCLES.SEND_MAIL')
                  )}
                </Button>
              </Box>
            }
          />
        )}
        <Accordion
          expanded={expanded === 'panel'}
          onChange={handleChange('panel')}
        >
          <AccordionSummary
            sx={{
              bgcolor: 'grey.main',
              paddingLeft: '20px'
            }}
          >
            <Typography variant='subtitle1'>
              {t('CYCLES.LIST_PARTICIPANTS')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant='caption' color={!isUpdate && 'grey.dark'}>
              {!formData.cycleParticipants.length
                ? t('CYCLES.ADD_PARTICIPANTS_CYCLE')
                : t('CYCLES.EDIT_PARTICIPANTS_CYCLE')}
              {!isUpdate && (
                <Typography variant='caption' color='grey.dark'>
                  {' '}
                  {t('CYCLES.ADD_PARTICIPANTS_TAG')}
                </Typography>
              )}
            </Typography>
            <List>
              {formData?.cycleParticipants.length > 0 && (
                <Grid item xs={12} display={'flex'} alignItems={'center'}>
                  <Checkbox
                    sx={{ p: 1, marginLeft: '-15px'}}
                    onChange={e => handleAllCheck(e)}
                    checked={allCheked}
                    />
                  <Typography color='primary'>
                    {t('CYCLES.JOURNEYS.SELECT_ALL')}
                  </Typography>
                </Grid>
              )}
              <Box key={listReload}>
              {formData?.cycleParticipants.map((participant, index) => {
                    return (
                        <Box key={participant.idParticipant}>
                            <ListItem
                                sx={{
                                  marginLeft: '-20px',
                                  bgcolor: 'theme.palette.primary.main'
                                }}
                                secondaryAction={
                                  <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginRight: '-50px' }}>
                                    <Grid container alignItems="center" spacing={1}>
                                        <Grid item>                                         
                                        {(!participant.isDeleted && lastJourneyDateFrom) && (
                                          <Tooltip title={t('CYCLES.MOVE')}>
                                            {/* Contenedor adicional para el icono */}
                                            <Box
                                              sx={{
                                                display: 'inline-block',
                                                marginRight: '-6px'
                                              }}
                                            >
                                              {/* SwapHorizIcon dentro de IconButton con estilos condicionales */}
                                              <IconButton
                                                sx={{
                                                  padding: '4px',
                                                  '&:hover': {
                                                    boxShadow: '0 0 0 4px #f5f5f5',
                                                  },
                                                }}
                                                onClick={() => { handleChangeCycleDialog(participant) }}
                                              >
                                                <SwapHorizIcon
                                                  sx={{
                                                    color: '#1c6189',
                                                    fontSize: '2rem',
                                                    borderRadius: '50%',
                                                  }}
                                                />
                                              </IconButton>
                                            </Box>
                                          </Tooltip>
                                        )}
                                  
                                        </Grid>
                                        <Grid item md={4} xs={4}>
                                        {(!participant.isDeleted && lastJourneyDateFrom) && (
                                            <BackspacePopover
                                                remove={() => { removeParticipant(participant)}}
                                                tooltipTitle={t('CYCLES.UNSUBSCRIBE')}
                                                isLoading={loadingParticipantId === participant.idParticipant}
                                                idParticipant={participant.idParticipant}
                                            />                                          
                                        )}
                                        </Grid>
                                    </Grid>
                                  </Box>
                                }
                            >
                            <ListItemIcon>
                                <Checkbox
                                    value={participant.participantEmail}
                                    sx={{ p: 0 }}
                                    onClick={handleCheckbox}
                                    checked={checkList(participant.participantEmail)}
                                    disabled={participant.isDeleted}
                                />
                            </ListItemIcon>
                            <ListItemText
                                sx={{ marginLeft: '-15px' }}
                                primaryTypographyProps={{ fontSize: 14 }}
                                primary={
                                    ((participant?.participantName && participant?.participantSurname) && participant?.idCycleJourneyOrigin !== null && participant?.idCycleJourneyOrigin !== undefined)
                                        ? (
                                          <Typography component='span' variant='body2' color='#0085B4'>
                                            <Paragliding fontSize="extrasmall"/> {participant.participantName} {participant.participantSurname}
                                          </Typography>
                                          )
                                        : participant?.participantName && participant?.participantSurname
                                        ? `${participant.participantName} ${participant.participantSurname}`
                                        : t('CYCLES.NO_USER_DATA')
                                }
                                secondary={
                                    <React.Fragment>
                                        {participant.participantEmail ? (
                                          <Typography
                                              sx={{
                                                  textOverflow: 'clip',
                                                  whiteSpace: 'normal',
                                                  wordWrap: 'break-word',
                                                  width: { xs: '150px', sm: '350px', md: '550px', lg: '550px', xl: '550px' }
                                                }} 
                                              component='span'
                                              variant='body2'
                                              color='text.primary'>
                                              {participant.participantEmail}
                                          </Typography>
                                        ) : (
                                          <Typography
                                              sx={{ 
                                                display: 'inline',
                                                width: { xs: '150px', sm: '350px', md: '550px', lg: '550px', xl: '550px' }
                                              }}
                                              variant='body2'
                                              color='text.primary'>
                                              {t('CYCLES.NO_EMAIL_DATA')}
                                          </Typography>
                                        )}
                                    </React.Fragment>
                                }
                            />
                            </ListItem>

                            <Divider />
                        </Box>
                    )
                })}
              </Box>
              <Button
                variant='contained'
                color='primary'
                sx={{ mb: 2, mt: 2 }}
                              fullWidth
                              disabled={!isUpdate || new Date(formData.cycleJourneys[formData.cycleJourneys.length-1]?.cycleJourneyDateTo).getTime() < Date.now()}
                onClick={() => setOpenTable(true)}
              >
                              <AddIcon />
                              {t('CYCLES.ADD_PARTICIPANTS_CYCLE')}
              </Button>
              {formData?.cycleParticipants.length > 0 && (
                <ButtonGroup
                  variant='contained'
                  fullWidth
                  size='medium'
                  disabled={!isUpdate}
                >
                  <Grid container spacing={.2}>
                    <Grid item xs={12} md={12}>
                      <Button
                        style={{height: '100%'}}
                        variant='contained'
                        startIcon={<EmailOutlinedIcon />}
                        color='secondary'
                        fullWidth
                        onClick={() => {
                          sendEmail()
                        }}
                      >
                        {t('CYCLES.SEND_MAIL')}
                      </Button>
                    </Grid>
                  </Grid>
                </ButtonGroup>
              )}
            </List>
          </AccordionDetails>
        </Accordion>
      </Card>
    </>
  )
}

export default SectionParticipants
