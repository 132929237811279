import { useState } from "react";
import { snackActions } from "@helpers/snackbarUtils";
import { useTranslation } from "react-i18next";

export const useHandleImages = () => {
  const { t } = useTranslation();
  const [state, setState] = useState({});
  const maxImgSize = 5120000;

  const manageImages = (e, formDataImgType) => {
    const typeImg = e.type.split("/");
    const size = e.file.size;
    
    if (typeImg[0] === "image") {
      if (size < maxImgSize) {
        const image = e.base64.replace("data:" + e.type + ";base64,", "");
        setState((prevState) => {
          return { ...prevState, [formDataImgType]: image };
        });
      } else {
        snackActions.error(t("WORKSHEET.FILE_SIZE"));
      }
    } else {
      snackActions.error(t("WORKER.NOT_IMAGE"));
    }
  }

  return {
    ...state,
    infoData: state,
    setInfoData: setState,
    maxImgSize,
    manageImages
  }

};
