import { makeStyles } from '@mui/styles';
import Image from '@assets/images/background_login_participants.png';
export const useStyles = makeStyles(theme => {
    return {
        loginParticipants: {
            backgroundImage: `linear-gradient(90deg, rgba(4,139,197,0.6) 0%, rgba(117,206,224,0.9) 100%),url(${Image})`,
            backgroundSize: 'cover',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
            [theme.breakpoints.down('sm')]: {
                backgroundImage: 'none',
            },
        },

        card: {
            maxWidth: '450px',
            background: 'white',
            padding: '1.5em 2em',
            [theme.breakpoints.down('sm')]: {
                margin: '10px',
                padding: '40px 25px',
            },
        },
        header: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '0px',
            height: '100px'
        },
        title: {
            margin: '20px 0 10px',
            color: theme.palette.primary.main,
            fontWeight: 'bold',
            fontSize:'1.2em',
            textAlign:'center'
        },
        title2: {
            margin: '20px 0 10px',
            color: theme.palette.secondary.main,
            fontWeight: 'bold',
            fontSize:'1.2em',
            textAlign:'center'
        },
        subtitle: {
            margin: '0px 5px'
        },
        image: {
            width: '30%',
            [theme.breakpoints.down('sm')]: {
                width: '25%',
            },
        }
        
    };
});