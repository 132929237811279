import apiRequest from '@helpers/apiRequest';

const deleteCycleJourneyParticipant = idCycleJourneysParticipants => {
    return apiRequest('DELETE', `CycleJourneysParticipants/Delete/${idCycleJourneysParticipants}`);
};

const deleteCycleJourneyInvited = idCycleJourneysParticipants => {
    return apiRequest('DELETE', `CycleJourneysParticipants/DeleteJourneyInvited/${idCycleJourneysParticipants}`);
};

const newCycleJourneParticipant = data => {
    return apiRequest('POST', 'CycleJourneysParticipants/New', data);
};

const updateCycleJourneParticipant = data => {
    const idCycleJourneysParticipants = data.idCycleJourneysParticipants;
    return apiRequest('PUT', `CycleJourneysParticipants/Update/${idCycleJourneysParticipants}`, data);
};

const cycleJourneParticipantDetailsByJourney = IdCycleJourney => {
    return apiRequest('GET', `CycleJourneysParticipants/DetailsByJourney/${IdCycleJourney}`);
};

const addParticipantParatrooper = data => {
    return apiRequest('POST', 'CycleJourneysParticipants/AddParticipantParatrooper', data)
  }
  
const deleteParticipantParatrooper = data => {
    return apiRequest('POST', 'CycleJourneysParticipants/DeleteParticipantParatrooper', data)
  }

  const checkParatrooper = (idParticipant, idCycleJourney) => {
    return apiRequest('GET', `CycleJourneysParticipants/CheckParatrooper/${idParticipant}/${idCycleJourney}`);
};

const serviceCycleJourneysParticipants = {
    deleteCycleJourneyParticipant,
    deleteCycleJourneyInvited,
    newCycleJourneParticipant,
    updateCycleJourneParticipant,
    cycleJourneParticipantDetailsByJourney,
    addParticipantParatrooper,
    deleteParticipantParatrooper,
    checkParatrooper
};


export default serviceCycleJourneysParticipants;