import React, { useState, useEffect } from 'react'
import Editor from '@components/CkEditor'
import Dialog from '@components/Dialog'
import FormActions from '@components/FormActions'
import HeaderTitle from '@components/HeaderTitle'
import LocaleTabs from '@components/LocaleTabs'
import Etiquetas from '@components/Etiquetas'
import SectionFiles from './SectionFiles'
import { snackActions } from '@helpers/snackbarUtils'
import serviceRabbitMq from '@services/serviceRabbitMq'
import { useForm } from '@hooks/useForm'
import {
  Box,
  Button,
  Grid,
  MenuItem,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Snackbar,
  Alert,
  Tooltip,
  FormControl,
  InputLabel,
  Select
} from '@mui/material'
import serviceTemplates from '@services/serviceTemplates'
import { useCommonStyles } from '@styles/common.style'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import TableTemplateMailing from '@components/TableTemplateMailing'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import HelpCenterIcon from '@mui/icons-material/HelpCenter'
import serviceMailing from '@services/serviceMailing'
import Card from '@components/Card'
import Loading from '@components/Loading'

const TemplateMailingDetail = () => {
  const classes = useCommonStyles()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [openDialog, setOpenDialog] = useState(false)
  const [labelDialog, setLabelDialog] = useState(false)
  const [copied, setCopied] = useState(false)

  const [categories, setCategories] = useState()
  const [tags, setTags] = useState(null)

  const [showTemplatesDialog, setShowTemplatesDialog] = useState(false)
  const [mailData, setMailData] = useState([])
  const [emailSubject, setEmailSubject] = useState('')
  const [emailBody, setEmailBody] = useState('')
  const lang = sessionStorage.getItem('lang')
  const idLang = lang === 'es' ? 1 : lang === 'en' ? 2 : lang === 'it' ? 3 : 1;
  const [loading, setLoading] = useState(true)

  const {
    state: { data: TEMPLATE_MAILING_DATA, type: TYPE }
  } = useLocation()

  const HEADER_TITLE =
    TYPE === 'NEW' ? t('MAILING.NEW_TITLE') : t('MAILING.UPDATE_TITLE')
  const HEADER_SUBTITLE =
    TYPE === 'NEW' ? t('MAILING.NEW_SUBTITLE') : t('MAILING.UPDATE_SUBTITLE')

  const values = TYPE === 'NEW' ? { idMailingType: 1, mailingName: '', locale: [] } : TEMPLATE_MAILING_DATA

  const { formData, setFormData, handleChange } = useForm(values)

  const disabledSaveButton = TEMPLATE_MAILING_DATA === formData  

  useEffect(() => {
    if (TEMPLATE_MAILING_DATA) {
      setFormData(TEMPLATE_MAILING_DATA)
    }
  }, [TEMPLATE_MAILING_DATA])

  useEffect(() => {
    if (TYPE !== 'NEW') {
      const data1 = formData.locale.find(item => item.idLanguage === idLang).mailingSubject
      const data2 = formData.locale.find(item => item.idLanguage === idLang).mailingBody
      setEmailSubject(data1) 
      setEmailBody(data2) 
    }
  }, [formData])

  useEffect(() => {
    serviceMailing.getMailingsCategories().then(response => {
      if (!response.error) {
        setCategories(response.data)
      }
    })
    serviceMailing.getTagsDetail().then(response => {
      if (!response.error) {
        setTags(response.data)
      }
      setLoading(false)
    })
  }, [])

  const handleSubmit = type => {
    let hasError = false
  
    // Verifica si faltan los datos requeridos
    if (!formData.mailingName) {
      snackActions.error(t('TEXT.ERROR_MESSAGE_TEMPLATE_CODE'))
      hasError = true
    }
  
    if (!formData.idMailingType) {
      snackActions.error(t('TEXT.ERROR_MESSAGE_MAILING_TYPE'))
      hasError = true
    }
  
    const emailSubjectExists = formData.locale.some(locale => locale.mailingSubject)
  
    if (!emailSubjectExists) {
      snackActions.error(t('TEXT.ERROR_MESSAGE_SUBJECT'))
      hasError = true
    }
  
    if (hasError) {
      setLoading(false)
      return
    }
  
    setLoading(true)
  
    switch (type) {
      case 'NEW':
        serviceTemplates.newMailing(formData).then(response => handleResponse(response, 'SAVE'))
        break
      case 'UPDATE':
        serviceTemplates.updateMailing(formData).then(response => handleResponse(response, 'SAVE'))
        break
      case 'DELETE':
        serviceTemplates.deleteMailing(formData).then(response => handleResponse(response, 'DELETE'))
        break
      default:
        break
    }
  }
  

  const handleResponse = (response, actionType) => {
    setLoading(false);
    if (!response.error) {
      if (actionType === 'DELETE') { //Al Eliminar vuelve a la página anterior
        navigate('/templateMailing');
      } else if (actionType === 'SAVE') { //Al Crear nuevo o Actualizar se queda en la página
        if (TYPE === 'NEW') {
          sessionStorage.setItem('mailingNew', JSON.stringify(response.data))
          serviceTemplates.getMailingDetail(response.data.idMailing).then(response => {
            if (!response.error) {
              setFormData(response.data) // Actualiza formData con los datos del mailing incluyendo idMailing
              navigate('/templateMailingDetail', {
                state: { data: response.data, type: 'UPDATE' },
              })
            }
          })
        }
      }
    }
  }
  

  const handleLocaleChange = (data, idLanguage, name) => {
    let localeLanguage = formData?.locale || []
    let index = localeLanguage.findIndex(
      locale => locale.idLanguage === idLanguage
    )
    if (index < 0) {
      localeLanguage.push({
        idLanguage: idLanguage,
        idMailing: formData.idMailing
      })
      index = localeLanguage.length - 1
    }
    localeLanguage[index][name] = data
    setFormData({ ...formData, locale: localeLanguage })
  }

  //Envío mail prueba

  const sendEmail = () => {
    const data = {
      idCycle: formData.idCycle,
      typeMail: 3,
      mails: [],
      locale: [
        {
          idLanguage: 1,
          mailingBody: emailBody,
          mailingSubject: emailSubject
        }
      ]
    }
    setMailData(data)
    setShowTemplatesDialog(true)
  }

  const sendMockEmail = () => {
    setShowTemplatesDialog(false)
    const data = {
      ...mailData,
      MailTo: mailData.mails,
      Locales: mailData.locale
    }
    serviceRabbitMq.sendDirectMail(data).then(response => {
      if (!response.error) {
          setShowTemplatesDialog(false)
      }
    })
  }

  if (loading) {
    return <Loading />
  }

  return (
    <div className={classes.container}>
      {labelDialog && (
        <Dialog
          width='80%'
          height='fit-content'
          centerContent={true}
          open={labelDialog}
          hasCloseBtn={true}
          title={t('LABEL.INFO_LABEL')}
          close={() => setLabelDialog(false)}
          content={<Etiquetas />}
        />
      )}
      <Dialog
        open={openDialog}
        hasCloseBtn={false}
        height={200}
        title={t('CONFIRM.TITLE_EMAIL')}
        subtitle={t('CONFIRM.UNRECOVERY_DELETE')}
        close={() => setOpenDialog(false)}
        actions={
          <Box display='flex' alignItems='center'>
            <Box mr={1}>
              <Button
                variant='contained'
                onClick={() => setOpenDialog(false)}
                color='error'
              >
                {t('BUTTON.CANCEL')}
              </Button>
            </Box>
            <Button variant='contained' onClick={() => handleSubmit('DELETE')}>
              {t('BUTTON.DELETE')}
            </Button>
          </Box>
        }
      />
    
      <Dialog
        open={showTemplatesDialog}
        width='70%'
        height='80%'
        hasCloseBtn={false}
        title={t('CYCLES.SEND_MAIL')}
        close={() => {
          setShowTemplatesDialog(false)
          setMailData('')
        }}
        content={
          <TableTemplateMailing
            mailData={mailData}
            setMailData={setMailData}
            disabled={false}
          />
        }
        actions={
          <Box display='flex' width='100%' justifyContent='flex-end'>
            <Button
              sx={{ mr: 1 }}
              variant='contained'
              color='error'
              onClick={() => {
                setShowTemplatesDialog(false)
              }}
              aria-label='move selected left'
            >
              {t('BUTTON.CLOSE')}
            </Button>
            <Button
              variant='contained'
              onClick={() => sendMockEmail()}
              aria-label='move selected left'
              disabled={mailData?.length == 0}
            >
              {t('CYCLES.SEND_MAIL')}
            </Button>
          </Box>
        }
      />
       

      <HeaderTitle title={HEADER_TITLE} subtitle={HEADER_SUBTITLE}>
        <FormActions
          show={TYPE === 'UPDATE' && values.idMailingType != 12}
          handleDelete={() => setOpenDialog(true)}
          handleUndo={() => {}}
          handleSave={() => handleSubmit(TYPE)}
          disabledButton={disabledSaveButton}
          urlBack='/templateMailing'
        />
      </HeaderTitle>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={copied}
        autoHideDuration={500}
        onClose={() => setCopied(false)}
      >
        <Alert
          variant='filled'
          sx={{
            width: 300,
            color: 'success'
          }}
          onClose={() => setCopied(false)}
          severity='success'
        >
          {t('MAILING.COPY_TO_CLIPBOARD_SUCCESS')}
        </Alert>
      </Snackbar>
      <Card style={{ width: '100%' }}>
        <Grid container spacing={2}>
          <Grid item md={3} xs={12}>
            <TextField
              label={t('LABEL.TEMPLATE_CODE')}
              name='mailingName'
              value={formData?.mailingName || ''}
              size='small'
              onChange={handleChange}
              margin='normal'
              fullWidth
              inputProps={{ maxLength: 100 }}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <FormControl fullWidth size='small' margin='normal'>
              <InputLabel id='category'>{t('TEXT.EMAIL_TYPE')}</InputLabel>
              <Select
                labelId='category'
                label={t('TEXT.EMAIL_TYPE')}
                value={formData?.idMailingType || ''}
                onChange={handleChange}
                name='idMailingType'
              >
                {categories?.map(category => (
                  <MenuItem
                    value={category.idMailingType}
                    key={category.idMailingType}
                  >
                    {category.mailingTypeName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Card>
      <Grid container spacing={2}>
        <Grid item xs={12} className={classes.tryEmail}>
              <Button onClick={(e) => sendEmail(e)}>{t('MAILING.TEST_EMAIL')}</Button>
        </Grid>
        <Grid item xs={12} mt={2}>
          <Card>
            <LocaleTabs
              locale={formData?.locale}
              content={(localeLanguage, lang) => (
                <Grid container>
                  <Grid item xs={12} md={5} mt={2}>
                    <TextField
                      fullWidth
                      margin='normal'
                      label={t('MAILING.SUBJECT')}
                      value={localeLanguage?.mailingSubject || ''}
                      size='small'
                      onChange={e => {
                        handleLocaleChange(
                          e.target.value,
                          lang.idLanguage,
                          'mailingSubject'
                        )
                      }}
                    />
                  </Grid>

                  <Grid container spacing={2} mt={2}>
                    <Grid item md={8} xs={12}>
                      <Editor
                        data={localeLanguage?.mailingBody || ''}
                        onChange={newData => {
                          handleLocaleChange(
                            newData,
                            lang.idLanguage,
                            'mailingBody'
                          )
                        }}
                      />
                      <SectionFiles
                        formData={localeLanguage}
                        setFormData={newData => {
                          handleLocaleChange(
                            newData,
                            lang.idLanguage,
                            'mailFiles'
                          )
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TableContainer
                        style={{
                          border: '1px solid #c4c4c4',
                          borderRadius: 4
                        }}
                      >
                        <Table>
                          <TableHead
                            style={{
                              background: '#f1f1f1',
                              height: '40px',
                              padding: '3px'
                            }}
                          >
                            <TableRow padding='none'>
                              <TableCell
                                padding='none'
                                style={{ paddingLeft: '20px' }}
                              >
                                {t('MAILING.COPY_TO_CLIPBOARD')}
                                <Tooltip title={t('LABEL.INFO_LABEL')}>
                                  <Button
                                    color='success'
                                    className={classes.buttonIcon}
                                  >
                                    <HelpCenterIcon
                                      onClick={() => setLabelDialog(true)}
                                    />
                                  </Button>
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell style={{ padding: '10px' }}>
                                {tags?.map((item, i) => (
                                  <CopyToClipboard
                                    text={item.tagName}
                                    onCopy={() => {
                                      setCopied(true)
                                    }}
                                  >
                                    <Tooltip
                                      title={t(
                                        'MAILINGS_TAGS.' + item.tagNameLiteral
                                      )}
                                      arrow
                                      placement='right'
                                    >
                                      <Button
                                        style={{ margin: '3px' }}
                                        variant='contained'
                                        size='medium'
                                        color='primary'
                                        endIcon={<ContentCopyIcon />}
                                      >
                                        {item.tagName}
                                      </Button>
                                    </Tooltip>
                                  </CopyToClipboard>
                                ))}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            />
          </Card>
        </Grid>
      </Grid>
    </div>
  )
}

export default TemplateMailingDetail
