import React, { useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { Grid, Card, Box, Button, Tooltip, Typography, Select, MenuItem, TextField } from '@mui/material'
import HeaderTitle from '@components/HeaderTitle';
import Dialog from '@components/Dialog'
import { useCommonStyles } from '@styles/common.style'
import { useStyles } from '@styles/evaluator.style'
import { useTranslation } from 'react-i18next'
import { snackActions } from '@helpers/snackbarUtils';
import serviceEvaluatorData from '@services/serviceEvaluatorData'
import { AuthContext } from '@context/auth/AuthContext'

const Panel = () => {
  const classes = useCommonStyles()
  const classes2 = useStyles()
  const { t } = useTranslation()  
  const navigate = useNavigate()
  const [ showVerificationDialog, setShowVerificationDialog ] = useState(true)
  const [ showColaboratorsNumberDialog, setShowColaboratorsNumberDialog ] = useState(false)
  const [ numberColaborators, setNumberColaborators ] = useState()
  const [ receiver, setReceiver ] = useState('')
  const [ isFirstTimeAsBoss, setIsFirstTimeAsBoss ] = useState(true)
  const [ comeFromQueries, setComeFromQueries ] = useState(false)
  const surveyCode = sessionStorage.getItem('surveyCode');
  const [ showNoDataDialog, setShowNoDataDialog ] = useState(false)
  const [ isNotFirstDialog, setIsNotFirstDialog ] = useState(false)
  const { logout } = useContext(AuthContext)
   
  useEffect(() => {
    fetchDataReceiver(surveyCode)
  }, [])

  useEffect(() => {
    sessionStorage.setItem( 'receiver', JSON.stringify( receiver ))

    const dataSessions = {
      pageSize: 100, 
      pageNumber: 0, 
      globalSearch: '', 
      search: [
          {
              SearchField: 'idEvaluatorParticipant', 
              SearchMode: 'EqualTo', 
              SearchValue: receiver.idEvaluatorParticipant, 
          },
          {
              SearchField: 'IdReceiverType', 
              SearchMode: 'EqualTo', 
              SearchValue: 1, 
          },
      ],
      order: [
          {
              OrderField: 'idEvaluatorSession',
              OrderAsc: true 
          },
      ]
    };

    if (receiver.length !== 0) {
      fetchSessions(dataSessions)
    }

  }, [receiver])
  
  useEffect(() => {
    if (numberColaborators === 0 && receiver?.isParticipant === true) {
      setShowColaboratorsNumberDialog(true)
    }
    setReceiver(prevState => ({
      ...prevState, 
      numReceivers: numberColaborators, 
    }));
  }, [numberColaborators])
  
  const fetchDataReceiver = async (code) => {
    const { data, error } = await serviceEvaluatorData.getEvaluatorParticipantFromCode(code);
    if (!error) {
      setReceiver(data)
      setNumberColaborators(data.numReceivers)
    }
  };

  const fetchSessions = async (dataSessions) => {
    const { data, error } = await serviceEvaluatorData.getSessions(dataSessions);
    if (!error) {
        if (data.length !== 0) {
          setIsFirstTimeAsBoss(false)
        } 
    }
  };

  const updateEvaluatorParticipant = async (participant) => {
    const { data, error } = await serviceEvaluatorData.updateEvaluatorParticipant(participant);
    if (!error) {
      setShowColaboratorsNumberDialog(false)
    }
  };
  
  const handleColabBtn = () => {
    if ( numberColaborators !== 0 && numberColaborators.length !== 0) {
      updateEvaluatorParticipant(receiver)
    } else {
      snackActions.error(t('EVALUATOR.ERROR_COLABORATORS'));
    }
  }

  const handleSourceBtn = () => {
    setComeFromQueries(true)
    setShowVerificationDialog(true)
  }

  const handleBtnStart = () => {
    if (comeFromQueries) {
      navigate('/reports')
    } else {
      navigate('/survey')
    }
  }

  return (
    <>
    {showColaboratorsNumberDialog && (
      <Dialog
        open={showColaboratorsNumberDialog ? true : false}
        width='auto'
        height='auto'
        hasCloseBtn={false}
        title={t('EVALUATOR.COLAB_NUMBER')}
        content={
          <div style={{display: 'flex', flexDirection: 'column'}}>
            <Typography mb={2}>{t('EVALUATOR.ENTER_NUMBER_COLLABS')}:</Typography>
            <TextField
                required
                label={t('EVALUATOR.COLAB_NUMBER')}
                name='collabNumber'
                onChange={(e) => {
                  let inputValue = e.target.value;
                  inputValue = inputValue.replace(/^0+/, '');
  
                  if (parseInt(inputValue) >= 0) {
                      setNumberColaborators(inputValue);
                  }
              }}
                size='small'
                inputProps={{ maxLength: 3 }}
                sx={{textAlign: 'center', width: 'auto !important', alignSelf: 'center'}}
            />
          </div>
        }
        actions={
          <Box display='flex' width='100%' justifyContent='flex-end'>
            <Button
              variant='contained'
              onClick={handleColabBtn}
              aria-label='move selected left'
            >
              Guardar
            </Button>
          </Box>
        }
      />
    )}
    <Dialog
      open={showNoDataDialog}
      width='auto'
      height='auto'
      hasCloseBtn={false}
      title={t('EVALUATOR.NO_DATA')}
      actions={
        <Box display='flex' width='100%' justifyContent='flex-end'>
          <Button
            variant='contained'
            onClick={() => setShowNoDataDialog(false)}
          >
            OK
          </Button>
        </Box>
      }
    />
    <Dialog
      open={isNotFirstDialog}
      width='auto'
      height='auto'
      hasCloseBtn={false}
      title={t('EVALUATOR.NO_FIRST_B1')}
      subtitle={t('EVALUATOR.NO_FIRST_B2')}
      actions={
        <Box display='flex' width='100%' justifyContent='flex-end'>
          <Button
            variant='contained'
            onClick={() => logout()}
          >
            OK
          </Button>
        </Box>
      }
    />
    <div className={classes.container}>
      <HeaderTitle
          title={t('EVALUATOR.AUTOEVALUATION')}
          subtitle={t('EVALUATOR.SUBTITLE')}
      />
      <br />
      <Card className={classes2.card}>
        {showVerificationDialog === false &&
        (<Grid container spacing={2}>
          <Grid item xs={12}>
            { receiver?.isParticipant === true && (
              <Grid container spacing={2} mt={0}>
                <Grid item xs={12} md={6}>
                  <Typography mb={2}>{t('EVALUATOR.BOSS_TEXT1')}:</Typography>
                  <Button className={classes2.btn} disabled={!isFirstTimeAsBoss} variant='contained' size='large' fullWidth onClick={() => setShowVerificationDialog(true)}>
                    {t('EVALUATOR.BOSS_BTN1')}
                  </Button>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography mb={2}>{t('EVALUATOR.BOSS_TEXT2')}:</Typography>
                  <Button className={classes2.btn} variant='contained' size='large' fullWidth onClick={() => handleSourceBtn()}>
                    {t('EVALUATOR.BOSS_BTN2')}
                  </Button>
                </Grid>
              </Grid>
            )}
            { receiver?.isParticipant === false &&
              (<Grid container spacing={2} mt={0} p={1}>
                <Grid item xs={12}>
                  <Typography mb={2}>
                    {t('EVALUATOR.COL_TEXT1')}
                  </Typography>
                </Grid>
                <Grid item xs={4} display={'flex'} justifyContent={'flex-end'} alignItems={'center'} mb={2}>
                  <Box className={classes2.box2}>
                    {t('EVALUATOR.IMPORTANT')}
                  </Box>
                </Grid>
                <Grid item xs={7.5} ml={1} mb={2}>
                  <Typography>
                    {t('EVALUATOR.COL_TEXT2')}
                  </Typography>
                </Grid>
                <Grid item xs={12} display={'flex'} justifyContent={'center'}>
                  <Button className={classes2.btn} variant='contained' size='large' onClick={() => setShowVerificationDialog(true)}>
                    {t('EVALUATOR.BTN_START')}
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>)}

        { showVerificationDialog === true &&
        (<Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography className={classes2.title}>{t('EVALUATOR.VERIFICATION')}</Typography>
          </Grid>
          <hr/>
          { receiver?.isParticipant === false && (
            <Grid item xs={12} sx={{textAlign: 'justify'}}>
              <Typography>{t('EVALUATOR.TEXT_COL1')}</Typography>
              <br/>
              <Typography>{t('EVALUATOR.TEXT_COL2')}</Typography>
              <br/>
              <Typography>{t('EVALUATOR.TEXT_COL3')}</Typography>
            </Grid>
          )}
          <Grid item xs={12} sx={{textAlign: 'center'}}>
            {receiver?.isParticipant === false && (
            <Typography>{t('EVALUATOR.CODE1')} <span className={classes2.bold}>{surveyCode}</span> {t('EVALUATOR.CODE2')}:</Typography>)}
            {receiver?.isParticipant === true && (
            <Typography>{t('EVALUATOR.CODE3')}:</Typography>)}
          </Grid>


          {/* <Grid item xs={5} display={'flex'} justifyContent={'flex-end'}>
            <Typography>{t('EVALUATOR.DEP_AREA')}:</Typography>
          </Grid>
          <Grid item xs={7} display={'flex'} justifyContent={'flex-start'}>
            <Typography style={{textTransform: 'uppercase', fontWeight: 'bold'}}>{receiver.participant.participantPosition}</Typography>
          </Grid> */}

          
          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <Typography style={{textTransform: 'uppercase', fontWeight: 'bold', color: '#0085b4'}}>{receiver?.participant?.participantName + ' ' + receiver?.participant?.participantSurname}</Typography>
          </Grid>
          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <Typography style={{textTransform: 'uppercase', fontWeight: 'bold', color: '#0085b4'}}>{receiver?.companyName}</Typography>
          </Grid>

          <Grid item xs={12} mt={2} mb={1} display={'flex'} justifyContent={'center'}>
            <Typography>{t('EVALUATOR.RIGHT')}</Typography>
          </Grid>
          
            <Grid container spacing={1} mt={1} justifyContent="center" alignItems="center">
              <Grid item>
                <Button className={classes2.btn} variant='contained' size='large'
                  sx={{width: '110px',marginX: 1}}
                  onClick={() => setShowNoDataDialog(true)}>
                {t('EVALUATOR.NO')}
              </Button>
            </Grid>
              <Grid item>
                <Button className={classes2.btn} variant='contained' size='large'
                  sx={{whiteSpace: 'nowrap',width: '110px',marginX: 1}}
                onClick={() => {
                    if(receiver?.isParticipant === true && !isFirstTimeAsBoss) {
                    setIsNotFirstDialog(true)
                  } else {
                    handleBtnStart()
                  }
                }}
              >
                {t('EVALUATOR.YES')}
              </Button>
            </Grid>
          </Grid>
        </Grid>)}
      </Card>
    </div>
  </>
  )
}

export default Panel
