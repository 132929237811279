const getCurrentJourney = cycle => {
  const date = new Date()
  var filtered = cycle.cycleJourneys.filter(
    cycleJourney => Date.parse(cycleJourney.cycleJourneyDateFrom) <= date
  )
  if (filtered.length > 0) {
    return filtered[filtered.length - 1]
  } else {
      return cycle.cycleJourneys[0] //cycle.cycleJourneys.length - 1
  }
}

export default getCurrentJourney
