import apiRequest from '@helpers/apiRequest'

const getCycles = data => {
  return apiRequest('POST', `Cycles/List/`, data)
}

const getCycleDetail = (idCycle, areParticipantsActive) => {
  return apiRequest('GET', `Cycles/Details/${idCycle}/${areParticipantsActive}`)
}

const getCyclesTypeActive = data => {
  return apiRequest('POST', `CyclesTypeActiveList/List`, data)
}

const getCycleDetailByKeysCycle = data => {
  const firstKeyCycle = data.firstKeyCycle
  const secondKeyCycle = data.secondKeyCycle
  return apiRequest('GET', `Cycles/${firstKeyCycle}/${secondKeyCycle}`)
}

const updateCycle = data => {
  const idCycle = data.idCycle
  return apiRequest('PUT', `Cycles/Update/${idCycle}`, data)
}
const newCycle = data => {
  //Si la modalidad viene a 0 (General) la ponemos por defecto como 1 (Presencial)
  if (data.CycleTypeMode == undefined || data.CycleTypeMode == 0) {
    data.CycleTypeMode = 1;
  }
  return apiRequest('POST', 'Cycles/New', data)
}
const deleteCycle = data => {
  const idCycle = data.idCycle
  return apiRequest('DELETE', `Cycles/Delete/${idCycle}`)
}

const newAddParticipants = data => {
  return apiRequest('POST', `Cycles/NewAddParticipants`, data)
}

const changeCycleParticipant = data => {
  return apiRequest('POST', `Cycles/ChangeCycleParticipant`, data)
}

const deleteCycleParticipant = data => {
  return apiRequest('POST', `Cycles/DeleteCycleParticipant`, data)
}

const byConsultant = idConsultant => {
  return apiRequest('GET', `Cycles/ByConsultant/${idConsultant}/false`)
}

const hideJourneys = data => {
  return apiRequest('POST', `Cycles/HideJourneys/`, data)
}
const updateCycleJourneys = data => {
  const idCycleJourney = data.idCycleJourney
  return apiRequest('PUT', `Cycles/UpdateCycleJourney/${idCycleJourney}`, data)
}

const serviceCycles = {
  getCycles,
  getCycleDetail,
  getCycleDetailByKeysCycle,
  updateCycle,
  deleteCycle,
  newCycle,
  newAddParticipants,
  changeCycleParticipant,
  deleteCycleParticipant,
  hideJourneys,
  byConsultant,
  updateCycleJourneys
}

export default serviceCycles
