import apiRequest from '@helpers/apiRequest'

const getCycleJourneyDetails = idCycleJourney => {
  return apiRequest('GET', `JourneySer/Details/${idCycleJourney}`)
}
const getCyclePreparation = (idCycle, idCycleJourney, idLanguage) => {
    return apiRequest('GET', `Cycles/Preparation/${idCycle}/${idCycleJourney}/${idLanguage}`)
}
const getCycleJourneyByCycle = idCycle => {
  return apiRequest('GET', `JourneySer/ListCycle/${idCycle}`)
}
const getCycleJourneyByConsultant = idConsultant => {
  return apiRequest('GET', `JourneySer/SerByConsultant/${idConsultant}`)
}
const getCycleJourneyByParticipant = idParticipant => {
  return apiRequest('GET', `JourneySer/SerByParticipant/${idParticipant}`)
}

const updateCycleJourney = data => {
  const idCycleJourney = data.idCycleJourney
  return apiRequest('PUT', `JourneySer/Update/${idCycleJourney}`, data)
}

const newCycleJourney = data => {
  return apiRequest('POST', 'JourneySer/New', data)
}

const deleteCycleJourney = data => {
  const idCycleJourney = data.idCycleJourney
  return apiRequest('DELETE', `JourneySer/Delete/${idCycleJourney}`)
}

const serviceCycleJourneys = {
  getCycleJourneyDetails,
  getCycleJourneyByCycle,
  updateCycleJourney,
  newCycleJourney,
  deleteCycleJourney,
  getCyclePreparation,
  getCycleJourneyByConsultant,
  getCycleJourneyByParticipant
}

export default serviceCycleJourneys
