import Loading from '@components/Loading';
import { AuthContext } from '@context/auth/AuthContext';
import { SnackbarProvider } from 'notistack';
import { useContext, useEffect } from 'react';
import './App.css';
import { SnackbarUtilsConfigurator } from './common/helpers/snackbarUtils';
import './assets/i18n/config';
import CampusRoutes from './areas/Campus/routes/CampusRoutes';
import ConsultantsRoutes from './areas/Consultants/routes/ConsultantsRoutes';
import ParticipantsRoutes from './areas/Participants/routes/ParticipantsRoutes';
import OWToolboxRoutes from './areas/OWToolbox/routes/OWToolboxRoutes';
import KitOWRoutes from './areas/KitOW/routes/KitOWRoutes';
import EvaluatorRoutes from './areas/Evaluator/routes/EvaluatorRoutes';
import SchedulerRoutes from './areas/Scheduler/routes/SchedulerRoutes';


function App() {
    const url = window.location.href;

    useEffect(() => {
        const lang = sessionStorage.getItem('lang');
        !lang && sessionStorage.setItem('lang', 'es');
    }, []);

    if (url.includes("consultores.ottowalter") || url.includes("consultoresow") || url.includes("consultants/") || url.includes("8021")) {
        if(sessionStorage.getItem('area') !== "consultant"){sessionStorage.setItem('changeArea', 'yes')}
        sessionStorage.setItem('area', 'consultant');
    }
    else if (url.includes("campusow.ottowalter") || url.includes("participantesow") || url.includes("participants/") || url.includes("8022")) {
        if(sessionStorage.getItem('area') !== "participant"){sessionStorage.setItem('changeArea', 'yes')}
        sessionStorage.setItem('area', 'participant');
    }
    else if (url.includes("admin.ottowalter") || url.includes("campusow.magicsolutions") || url.includes("campus/") || url.includes("8020")) {
        if(sessionStorage.getItem('area') !== "campus"){sessionStorage.setItem('changeArea', 'yes')}
        sessionStorage.setItem('area', 'campus');
    }
    else if (url.includes("toolboxow.ottowalter") || url.includes("owtoolbox") || url.includes("owtoolbox/") || url.includes("8023")) {
        if(sessionStorage.getItem('area') !== "owtoolbox"){sessionStorage.setItem('changeArea', 'yes')}
        sessionStorage.setItem('area', 'owtoolbox');
    }
    else if (url.includes("kitow") || url.includes("kitow/") || url.includes("8024")) {
        if(sessionStorage.getItem('area') !== "kitow"){sessionStorage.setItem('changeArea', 'yes')}
        sessionStorage.setItem('area', 'kitow');
    }
    else if (url.includes("medidorow") || url.includes("evaluadorow") || url.includes("evaluator/") || url.includes("8025")) {
        if(sessionStorage.getItem('area') !== "evaluator"){sessionStorage.setItem('changeArea', 'yes')}
        sessionStorage.setItem('area', 'evaluator');
    }
    else if (url.includes("agendaow") || url.includes("scheduler/") || url.includes("8026")) {
        if(sessionStorage.getItem('area') !== "scheduler"){sessionStorage.setItem('changeArea', 'yes')}
        sessionStorage.setItem('area', 'scheduler');
    }
    else {
        if (sessionStorage.getItem('area') === null) {
            sessionStorage.setItem('area', 'campus');
        }
    }

    const { status } = useContext(AuthContext);
    if (status === 'checking') return <Loading />;

    const areaName = sessionStorage.getItem('area');

    return (
        <SnackbarProvider>
            <SnackbarUtilsConfigurator />
            <div>
                {areaName === 'consultant' ? <ConsultantsRoutes /> : null}
            </div>
            <div>
                {areaName === 'participant' ? <ParticipantsRoutes /> : null}
            </div>
            <div>
                {areaName === 'campus' ? <CampusRoutes /> : null}
            </div>
            <div>
                {areaName === 'owtoolbox' ? <OWToolboxRoutes /> : null}
            </div>
            <div>
                {areaName === 'kitow' ? <KitOWRoutes /> : null}
            </div>
            <div>
                {areaName === 'evaluator' ? <EvaluatorRoutes /> : null}
            </div>
            <div>
                {areaName === 'scheduler' ? <SchedulerRoutes /> : null}
            </div>
        </SnackbarProvider>
    );
}

export default App;