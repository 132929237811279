import React, { useState, useEffect, useCallback } from 'react'
import { Box, Grid, TextField, Typography, Chip, Button } from '@mui/material'
import CheckBox from '@components/CheckBox'
import ReactDevicePreview from 'react-device-preview'
import serviceMobileSheets from '@services/serviceMobileSheets'
import { useCommonStyles } from '@styles/common.style'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import LocaleTabs from '@components/LocaleTabs'
import Card from '@components/Card'
import { useForm } from '@hooks/useForm'
import DraggableElements from './components/DraggableElements'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import HeaderTitle from '@components/HeaderTitle'
import FormActions from '@components/FormActions'
import Dialog from '@components/Dialog'
import ColorPicker from '@components/ColorPicker'
import Loading from '@components/Loading'
import ContentMobile from '@components/ContentMobile'
import { snackActions } from '@helpers/snackbarUtils'

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}

const OwToolboxDetail = () => {
  const [openDialog, setOpenDialog] = useState(false)
  const [typesList, setTypesList] = useState([])
  const [loading, setLoading] = useState(true)
  const [showAddElement, setShowAddElement] = useState(false)

  const { t } = useTranslation()
  const navigate = useNavigate()
  const classes = useCommonStyles()

  const {
    state: { data: MOBILESHEET_DATA, type: TYPE }
  } = useLocation()

  const HEADER_TITLE =
    TYPE === 'NEW' ? t('OWTOOLS.NEW_TITLE') : t('OWTOOLS.UPDATE_TITLE')
  const HEADER_SUBTITLE =
    TYPE === 'NEW' ? t('OWTOOLS.NEW_SUBTITLE') : t('OWTOOLS.UPDATE_SUBTITLE')

  const initialFormData = {
    mobileSheetLogo: false,
    mobileSheetItems: [],
    mobileSheetColor: '#F1F1F1'
  }
  
  const { formData, handleChange, setFormData } = useForm(
    MOBILESHEET_DATA
      ? {
          ...MOBILESHEET_DATA,
          mobileSheetItems: MOBILESHEET_DATA.mobileSheetItems
            .sort((a, b) => a.mobileSheetItemOrder - b.mobileSheetItemOrder)
            .map((e, i) => ({
              ...e,
              idDrag: e.idDrag || `Item-${e.idMobileSheetItem || i}-${Date.now()}`
            })),
          mobileSheetLogo: MOBILESHEET_DATA.mobileSheetLogo !== undefined ? MOBILESHEET_DATA.mobileSheetLogo : false
        }
      : initialFormData
  )

  const checkMobileSheetCodeInFrontend = (mobileSheetCode, existingCodes) => {
    if (existingCodes.includes(mobileSheetCode)) {
      snackActions.error('El código MobileSheetCode ya existe.');
      return false;
    }
    return true;
  };
  
  
  
  const handleResponse = useCallback(
    async (response, actionType) => {
      if (!response.error) {
        if (actionType === 'DELETE') {
          navigate('/owToolbox');
        } else {
          const updatedId = actionType === 'NEW' ? response.data.idMobileSheet : formData.idMobileSheet;
          try {
            const result = await serviceMobileSheets.getMobileSheetsDetails(updatedId, true);
            const { data, error } = result;
            if (!error) {
              const updatedItems = data.mobileSheetItems.map((item, index) => ({
                ...item,
                idDrag: item.idDrag || `Item-${item.idMobileSheetItem || index}-${Date.now()}`
              }));
              setFormData({ ...data, mobileSheetItems: updatedItems });
              navigate(`/owToolboxDetail`, {
                state: { data, type: 'UPDATE' }
              });
            }
          } catch (error) {
            // Manejo de error (opcional)
          }
        }
      }
      setLoading(false)
    },
    [navigate, formData.idMobileSheet, setFormData]
  );
  
  
  const handleSubmit = useCallback(async (type) => {
    setLoading(true)
    let errors = []
  
    if (type !== 'DELETE') {
      // Validar si el código ya existe solo si no se está eliminando
      const codeCheckResponse = await serviceMobileSheets.checkMobileSheetCode(formData.mobileSheetCode)
      if (codeCheckResponse.error) {
        // Muestra directamente el error específico de que el código ya existe
        snackActions.error(codeCheckResponse.message);
        setLoading(false);
        return;
      }
    }
  
    if (!formData.mobileSheetLocale || formData.mobileSheetLocale.some(locale => !locale.mobileSheetTitle)) {
      errors.push(t('OWTOOLS.WORKSHEET_TITLE_ERROR'))
    }
    
    const itemErrors = validateItems()
    if (itemErrors.length > 0) {
      errors = errors.concat(itemErrors)
    }
    
    if (!formData.mobileSheetName) {
      errors.push(t('OWTOOLS.WORKSHEET_NAME_ERROR'))
    }
  
    if (errors.length > 0) {
      snackActions.error(errors.join(', '))
      setLoading(false)
      return
    }
  
    switch (type) {
      case 'NEW':
        serviceMobileSheets.newMobileSheet(formData).then(response => handleResponse(response, 'NEW'))
        break
      case 'UPDATE':
        serviceMobileSheets.updateMobileSheet(formData).then(response => handleResponse(response, 'UPDATE'))
        break
      case 'DELETE':
        serviceMobileSheets.deleteMobileSheet(formData).then(response => handleResponse(response, 'DELETE'))
        break
      default:
        setLoading(false)
        break
    }
  }, [formData, handleResponse])
  


  useEffect(() => {
    serviceMobileSheets.getListMobileSheet().then(res => {
      setTypesList(res.data);
  
      // Mostrar el botón de "Añadir elemento"
      if (!MOBILESHEET_DATA?.idMobileSheet) {
        setShowAddElement(true)
        handleAddItem() // Agregar un elemento automáticamente si no hay idMobileSheet
      } else {
        setShowAddElement(true) // El botón se muestra haya idMobileSheet o no
      }
  
      setLoading(false) // Desactivar loading al final
    }).catch(() => setLoading(false)) // Desactivar loading en caso de error
  }, [])
  

  const validateItems = useCallback(() => {
    let errors = []
    let contentErrorAdded = false

    for (let item of formData.mobileSheetItems) {
      if (!item.mobileSheetItemLocale || item.mobileSheetItemLocale.length === 0) {
        if (!contentErrorAdded) {
          errors.push(t('OWTOOLS.CONTENT_ERROR'))
          contentErrorAdded = true
        }
      }
    }
    return errors
  }, [formData.mobileSheetItems, t])

  const onDragEnd = useCallback(e => {
    const { destination, source } = e
    if (!destination) return
    const newItems = reorder(
      formData.mobileSheetItems,
      source.index,
      destination.index
    ).map((e, i) => ({...e,
      mobileSheetItemOrder: (i + 1) }))
    setFormData(prevState => ({ ...prevState, mobileSheetItems: newItems }))
  }, [formData.mobileSheetItems, setFormData])

  const handleChangeLocaleTitle = useCallback((data, idLanguage, name) => {
    let localeLanguage = formData?.mobileSheetLocale || []
    let index = localeLanguage.findIndex(
      locale => locale.idLanguage === idLanguage
    )
    if (index < 0) {
      localeLanguage.push({
        idLanguage: idLanguage
      })
      index = localeLanguage.length - 1
    }
    localeLanguage[index][name] = data
    setFormData({ ...formData, mobileSheetLocale: localeLanguage })
  }, [formData, setFormData])

  const handleChangeColor = useCallback((color, name) => {
    setFormData({ ...formData, [name]: color })
  }, [formData, setFormData])

  const handleAddItem = useCallback(() => {
    const newItem = {
      idDrag: `Item-${formData.mobileSheetItems.length}-${Date.now()}`,
      // idMobileSheetItemType: 1,
      mobileSheetItemTextColor: '#000000',
      mobileSheetItemBackColor: '#E2EDF3',
      mobileSheetItemAlign: 'center',
      mobileSheetItemIndent: false,
      mobileSheetItemBorder: false,
      mobileSheetItemLocale: [],
      mobileSheetItemOrder: formData.mobileSheetItems.length
    }
    setFormData(prevState => ({
      ...prevState,
      mobileSheetItems: [...prevState.mobileSheetItems, newItem]
    }))
    setShowAddElement(true)
  }, [formData.mobileSheetItems, setFormData])


  if (!typesList.length) return <Loading />

  if (loading) {
    return <Loading />
  }

  return (
    <div className={classes.container}>
      <Dialog
        open={openDialog}
        hasCloseBtn={false}
        height={200}
        title={t('CONFIRM.TITLE_TOOLBOX')}
        subtitle={t('CONFIRM.UNRECOVERY_DELETE2')}
        close={() => setOpenDialog(false)}
        actions={
          <Box display='flex' alignItems='center'>
            <Box mr={1}>
              <Button
                variant='contained'
                onClick={() => setOpenDialog(false)}
                color='error'
              >
                {t('BUTTON.CANCEL')}
              </Button>
            </Box>
            <Button variant='contained' onClick={() => handleSubmit('DELETE')}>
              {t('BUTTON.DELETE')}
            </Button>
          </Box>
        }
      />
      <HeaderTitle title={HEADER_TITLE} subtitle={HEADER_SUBTITLE}>
        <FormActions
          show={TYPE === 'UPDATE'}
          handleDelete={() => setOpenDialog(true)}
          handleUndo={() => {}}
          handleSave={() => handleSubmit(TYPE)}
          urlBack='/owToolbox'
        />
      </HeaderTitle>

      <Grid item lg={4.5} xs={12} style={{ marginBottom: '20px' }}>
        <Typography variant='caption'>
          {t('OWTOOLS.WORKSHEET_NAME')}
        </Typography>
        <TextField
          size='small'
          name='mobileSheetName'
          fullWidth
          value={formData.mobileSheetName}
          onChange={handleChange}
        />
      </Grid>

      <Grid container spacing={2}>
        <Grid item lg={9}>
          <Grid container spacing={2}>
            <Grid item lg={6} xs={12}>
              <Card fullHeight>
                <LocaleTabs
                  locale={formData.mobileSheetLocale}
                  content={(localeLanguage, lang) => (
                    <Box mt={2}>
                      <Typography variant='caption'>
                        {t('OWTOOLS.WORKSHEET_TITLE')}
                      </Typography>
                      <TextField
                        size='small'
                        name='mobileSheetTitle'
                        fullWidth
                        value={localeLanguage?.mobileSheetTitle || ''}
                        onChange={e =>
                          handleChangeLocaleTitle(
                            e.target.value,
                            lang.idLanguage,
                            'mobileSheetTitle'
                          )
                        }
                      />
                    </Box>
                  )}
                />
              </Card>
            </Grid>
            <Grid item lg={6} xs={12}>
              <Card fullHeight>
                <Typography
                  color='primary'
                  variant='subtitle1'
                  mb={2}
                  textAlign='center'
                  className={classes.cardTitle}
                >
                  {t('CYCLE_TYPES.TITLE_GLOBAL_CONFIG').toUpperCase()}
                </Typography>
                <Grid
                  container
                  spacing={2}
                  display='flex'
                  alignItems='flex-end'
                >
                  <Grid item xs={5}>
                    <Typography variant='caption' component='div'>
                      {t('OWTOOLS.WORKSHEET_UNLOCK_CODE')}
                    </Typography>
                    <TextField
                      size='small'
                      name='mobileSheetCode'
                      fullWidth
                      value={formData?.mobileSheetCode || ''}
                      onChange={handleChange}
                      inputProps={{ maxLength: 10 }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant='caption' component='div'>
                      {t('LABEL.BACKGROUND')}
                    </Typography>
                    <ColorPicker
                      bgColor={formData.mobileSheetColor}
                      onChangeComplete={e => {
                        handleChangeColor(e.hex, 'mobileSheetColor')
                      }}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <CheckBox
                      fullWidth
                      label={t('LABEL.SHOW_LOGO')}
                      name='mobileSheetLogo'
                      value={formData?.mobileSheetLogo || ''}
                      checked={formData?.mobileSheetLogo}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <DraggableElements
                  items={formData.mobileSheetItems}
                  onDragEnd={onDragEnd}
                  formData={formData}
                  setFormData={setFormData}
                  typesList={typesList}
                />
                {showAddElement && (
                  <Chip
                    size='small'
                    onClick={handleAddItem}
                    label={t('LABEL.ADD_ELEMENT')}
                    color='primary'
                    sx={{ padding: 0 }}
                    icon={<AddCircleIcon />}
                  />
                )}
              </Card>
            </Grid>
          </Grid>
        </Grid>

        <Grid item lg={3} xs={12}>
          <Box
            p={2}
            style={{
              position: 'sticky',
              top: 15
            }}
          >
            <ReactDevicePreview device='nexus5' scale='1'>
              <ContentMobile formData={formData} />
            </ReactDevicePreview>
          </Box>
        </Grid>
      </Grid>
    </div>
  )
}

export default OwToolboxDetail
